import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const ProfileBanner = ({ button }) => {
  const { t } = useTranslation();
  return (
    <section className="projects-banner">
      <div className="container">
        <div className="projects-banner-wrap">
          <div className="projects-banner-title">
            <h1>{t("your_profile")}</h1>
          </div>
          <div className="projects-nav">
            <ul>
              <li>
                <NavLink exact to="/profile">
                  {t("overview")}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/profile/edit-profile">
                  {t("edit_profile")}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/profile/membership">
                  {t("membership")}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/profile/order-history">
                  {t("order_history")}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/profile/settings">
                  {t("settings")}
                </NavLink>
              </li>
            </ul>
          </div>
          {button}
        </div>
      </div>
    </section>
  );
};

ProfileBanner.propTypes = {
  button: PropTypes.element,
};

export default ProfileBanner;
