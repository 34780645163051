import React from "react";
import { Link } from "react-router-dom";

const { FACEBOOK_LOGO, INSTAGRAM_LOGO, APP_LOGO } = require("../../assets");

const MembershipContainer = () => {
  return (
    <React.Fragment>
      <section className="membership membership-background">
        <div className="container">
          <div className="membership-header">
            <div className="membership-logo">
              <APP_LOGO />
              <span>Power up your life to the next level</span>
            </div>
            <div className="membership-right">
              <ul>
                <li>
                  <Link to="/login" className="button button-inverse">
                    Sign in
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="button">
                    Register
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="membership-title">
            <h1>Choose your membership</h1>
          </div>
          <div className="membership-pricelist">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="pricelist-item">
                  <div className="pricelist-header">
                    <h1>Free</h1>
                  </div>
                  <div className="pricelist-content">
                    <ul>
                      <li>Use the ywt App</li>
                      <li>Motivational Quotes</li>
                      <li>Free Meditations</li>
                      <li>Free Learning Content</li>
                      <li>Connect with Users</li>
                      <li>Discover Projects</li>
                      <li>And much more...</li>
                    </ul>
                  </div>
                  <div className="pricelist-button">
                    <Link to="/register" className="button regular">
                      Choose
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4">
                <div className="pricelist-item popular">
                  <div className="pricelist-header">
                    <h1>Premium</h1>
                  </div>
                  <div className="pricelist-content">
                    <ul>
                      <li>Exclusive Meditations</li>
                      <li>Motivational Quotes</li>
                      <li>Exclusive Learning Content</li>
                      <li>Start your Project</li>
                      <li>Boost your Project</li>
                      <li>Unlimited Swipes Meetpoint</li>
                      <li>And much more...</li>
                    </ul>
                  </div>
                  <div className="pricelist-price">
                    <h1>$84.00</h1>
                    <span>Yearly</span>
                  </div>
                  <div className="pricelist-button">
                    <Link to="/" className="button">
                      Choose
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="membership-social">
            <span>Follow our content on social media</span>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/yeswetrust/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <INSTAGRAM_LOGO />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/yeswetrust.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FACEBOOK_LOGO />
                </a>
              </li>
            </ul>
          </div>
          <div className="membership-footer">
            <a href="www.yeswetrust.com">www.yeswetrust.com</a>
            <ul>
              <li>
                <a href="">Imprint</a>
              </li>
              <li>
                <a href="">Terms & Conditions </a>
              </li>
              <li>
                <a href="">Data Privacy</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

MembershipContainer.propTypes = {};

export default MembershipContainer;
