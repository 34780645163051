export const executeAllPromises = async promises => {
  // Wrap all Promises in a Promise that will always "resolve"
  const resolvingPromises = promises.map(promise => {
    return new Promise(resolve => {
      const data = {};
      promise
        .then(result => {
          data.data = result.data.data;
        })
        .catch(error => {
          data.error = { ...error, ...error.data };
        })
        .then(() => {
          resolve(data);
        });
    });
  });

  // Execute all wrapped Promises
  return Promise.all(resolvingPromises);
};
