export const AUTH_TOKEN = "token";

export const getToken = () => localStorage.getItem(AUTH_TOKEN);

export const setToken = token => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getApiUrl = () => {
  return "https://app.yeswetrust.com/api/v1/";
};

export const isAuth = () => {
  let token = getToken();

  if (token) {
    if (token.split(".").length === 3) {
      try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const exp = JSON.parse(window.atob(base64)).exp;
        if (typeof exp === "number") {
          return Math.round(new Date().getTime() / 1000) < exp;
        }
      } catch (e) {
        return true;
      }
    }
    return true;
  }
  return false;
};
