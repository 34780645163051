import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ThirdPartyAuthContainer from "../../containers/ThirdPartyAuth";
import DebouncedInput from "../UI/DebouncedInput";

const RegisterForm = ({
  changeHandler,
  checkUsernameHandler,
  usernameAvailability,
  state,
}) => {
  const [data, setData] = useState({ password: "", cpassword: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const { error } = useSelector(s => s.authState);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="row justify-content-end">
        <div className="col-md-7 col-lg-7 col-xl-6">
          <div className="login-wrapper">
            <div className="login-top">
              <p>{t("already_have_account")}</p>
              <Link to="/auth/login" className="button">
                {t("sign_in")}
              </Link>
            </div>
            <div className="login-title">
              <h1>{t("register_to_ywt")}</h1>
              <p>{t("enter_details")}</p>
            </div>
            <div className="login-form">
              <form
                onChange={changeHandler}
                onSubmit={e => {
                  e.preventDefault();
                  if (data.password === data.cpassword && usernameAvailability)
                    return history.push("/auth/register/confirm");
                }}
                action=""
              >
                <p>{error || ""}</p>
                {/* <div
                  className={
                    "login-input ge-input " +
                    (usernameAvailability === undefined
                      ? ""
                      : usernameAvailability
                      ? "ge-input-valid"
                      : "ge-input-invalid")
                  }
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("username")}
                    defaultValue={state.username}
                    name="username"
                    required
                    onChange={checkUsernameHandler}
                  />
                </div> */}
                <DebouncedInput
                  changeHandler={checkUsernameHandler}
                  isCorrect={usernameAvailability}
                  value={state.username}
                  name="username"
                  placeholder={t("username")}
                  required={true}
                  divClassName={"login-input ge-input "}
                  inputClassName={"form-control"}
                />
                <div className="login-input ge-input">
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("email")}
                    defaultValue={state.email}
                    name="email"
                    required
                  />
                </div>
                <div className="login-input ge-input ge-input-password">
                  <input
                    id="password-field"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    placeholder={t("password")}
                    defaultValue={state.password}
                    required
                    onChange={e => {
                      setData({ ...data, password: e.target.value });
                    }}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className={
                      "toggle-password " +
                      (showPassword ? "pass-eye" : "pass-eye-off")
                    }
                  ></span>
                </div>
                <div className="login-input ge-input ge-input-password">
                  <input
                    id="password-field-1"
                    type={showCPassword ? "text" : "password"}
                    className="form-control"
                    name="cpassword"
                    placeholder={t("cpassword")}
                    defaultValue={state.cpassword}
                    required
                    onChange={e => {
                      setData({ ...data, cpassword: e.target.value });
                    }}
                    onBlur={() => setDisplayError(true)}
                  />
                  <span
                    onClick={() => setShowCPassword(!showCPassword)}
                    className={
                      "toggle-password " +
                      (showCPassword ? "pass-eye" : "pass-eye-off")
                    }
                  ></span>
                </div>
                <p>
                  {!displayError
                    ? ""
                    : data.password === data.cpassword
                    ? ""
                    : t("c_and_password_same")}
                </p>

                <div className="login-submit">
                  <input type="submit" className="button" value="Register now" />
                </div>
              </form>
            </div>
            <ThirdPartyAuthContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  checkUsernameHandler: PropTypes.func.isRequired,
  usernameAvailability: PropTypes.bool,
  state: PropTypes.object.isRequired,
};

export default RegisterForm;
