import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { QUOTE } from "../../assets";

const ProfileOverview = () => {
  const { t } = useTranslation();
  const {
    profile,
    interests,
    followingStats: { data: followingStats },
    visionBoard,
    projects: { data: projects },
    ...user
  } = useSelector(s => s.user);

  return (
    <section className="settings">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="settings-profile">
              <div className="settings-profile-img">
                <img src={user.media_url} alt="" />
              </div>
              <div className="settings-profile-content">
                <h1>{user.name}</h1>
                <span>{"@" + user.username}</span>
                <p>{user.bio}</p>
              </div>
              <div className="settings-profile-info">
                <ul>
                  <li>
                    <div className="item">
                      <p>{t("posts")}</p>
                      <span>{followingStats.posts}</span>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <p>{t("followers")}</p>
                      <span>{followingStats.followers}</span>
                    </div>
                  </li>
                  <li>
                    <div className="item">
                      <p>{t("following")}</p>
                      <span>{followingStats.following}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="settings-profile-interest">
                <h1 className="title">{t("interests")}</h1>
                <ul>
                  {(interests || []).map(interest => (
                    <li key={interest.id}>{interest.name}</li>
                  ))}
                </ul>
              </div>
              <div className="settings-profile-quote">
                <h1 className="title">{t("quote")}</h1>
                <QUOTE />
                <h2>{profile.quote}</h2>
              </div>
              <div className="settings-profile-project">
                <h1 className="title">{t("projects")}</h1>
                <div className="settings-profile-project-wrap">
                  {(projects || []).map(project => (
                    <div key={project.id} className="settings-profile-project-item">
                      <a href="javascript:void(0);">
                        <div className="settings-profile-project-img">
                          <img src={project.media_id} alt="project" />
                        </div>
                        <div className="settings-profile-project-title">
                          <h2>{project.title}</h2>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="settings-profile-visionboard">
                <h1 className="title">{t("visionboard")}</h1>
                <div className="settings-profile-visionboard-img">
                  <img src="img/tree-activity.png" alt="tree" />
                </div>
              </div>
              <div className="settings-profile-insta">
                <h1 className="title">{t("instagram_feed")}</h1>
                <div className="settings-profile-insta-wrap">
                  {!user.has_instagram_connected
                    ? null
                    : (user.instagramFeed.data || []).map((feed, index) => (
                        <div key={index} className="settings-profile-insta-item">
                          <a href="javascript:void(0);">
                            <img src={feed.image} alt="insta feed" />
                          </a>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProfileOverview.propTypes = {};

export default ProfileOverview;
