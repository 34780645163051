import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import ProjectFormBanner from "./Banner";
import { getProjectInterests } from "../../../containers/Projects/actions";
import { toggleModal } from "../../../containers/constants";
import { useTranslation } from "react-i18next";

const Step7 = ({ changePageHandler, onChangeHandler, onSubmitHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);
  const interests = useSelector(state => state.projectState.interests);
  // const [interests, setInterests] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!interests || interests.length === 0) dispatch(getProjectInterests());
  }, [interests, dispatch]);

  const setSelected = id => {
    for (let el of state.interests || []) if (el.id === id) return true;
    return false;
  };

  const submitHandler = e => {
    if (!state.interests || state.interests.length < 1) {
      e.preventDefault();
      dispatch(
        toggleModal({
          show: true,
          message: t("select_an_interest"),
          isError: true,
        })
      );
    } else {
      onSubmitHandler(e);
    }
  };

  if (!interests || interests.length === 0) return null;

  return (
    <form onSubmit={submitHandler} ref={form}>
      <ProjectFormBanner
        submitHandler={(e, location) => changePageHandler(form, e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-content">
            <h1>{t("chose_project_status")}</h1>
            <p>
              Usu labore discere interpretaris ne, <br />
              alia imperdiet comprehensam sed ex.
            </p>
          </div>
          <div className="projects-new-tabs">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  onClick={e => {
                    e.preventDefault();
                    onChangeHandler({ target: { name: "public", value: 1 } });
                  }}
                  className={"nav-link " + (state.public ? "active" : "")}
                  data-toggle="tab"
                  href="#public"
                  role="tab"
                  aria-selected="true"
                >
                  <i className="icon-world"></i>
                  {t("public")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={e => {
                    e.preventDefault();
                    onChangeHandler({ target: { name: "public", value: 0 } });
                  }}
                  className={"nav-link " + (!state.public ? "active" : "")}
                  data-toggle="tab"
                  href="#private"
                  role="tab"
                  aria-selected="false"
                >
                  <i className="icon-lock"></i>
                  {t("private")}
                </a>
              </li>
            </ul>
            <div className="tab-content improve-tabs-content reveal">
              {state.public ? (
                <div className="tab-pane fade show active" id="public">
                  <div className="projects-new-tabs-content">
                    <p>{t("public_status")}</p>
                  </div>
                </div>
              ) : (
                <div className="tab-pane fade show active" id="private">
                  <div className="projects-new-tabs-content">
                    <p>{t("private_status")}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="check-input-wrap">
              <h1>{t("im_interested_in")}</h1>
              {!interests
                ? null
                : interests.map((interest, index) => (
                    <div key={index} className="check-input">
                      <input
                        id={"check-" + interest.id}
                        checked={setSelected(interest.id)}
                        onClick={() =>
                          onChangeHandler({
                            target: {
                              name: "interests",
                              value: interest,
                              type: "interests",
                            },
                          })
                        }
                        name="check-2"
                        type="checkbox"
                      />
                      <label htmlFor={"check-" + interest.id}>
                        {interest.title}{" "}
                        {!interest.description ? null : (
                          <span>{"(" + interest.description + ")"}</span>
                        )}
                      </label>
                    </div>
                  ))}
            </div>
          </div>
          <div className="projects-new-input ge-send">
            <input
              type="submit"
              value={state.id ? t("update_project") : t("create_project")}
              className="button"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

Step7.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};

export default Step7;
