import React from "react";
import { useTranslation } from "react-i18next";
// import PropTypes from "prop-types";

import { APP_LOGO, FACEBOOK_LOGO, INSTAGRAM_LOGO } from "../../assets";

const Aside = () => {
  const { t } = useTranslation();

  return (
    <div className="login-more">
      <div className="login-more-top">
        <APP_LOGO />
        <p>{t("auth_quote")}</p>
      </div>
      <div className="login-more-bottom">
        <span>{t("follow_us")}</span>
        <ul>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/yeswetrust/"
            >
              <INSTAGRAM_LOGO />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/yeswetrust.ch"
            >
              <FACEBOOK_LOGO />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

Aside.propTypes = {};

export default Aside;
