import React from "react";

const NotFoundPage = () => {
  return (
    <section
      className="membership membership-background"
      style={{ position: "absolute", top: 0, bottom: 0 }}
    >
      <div className="container">
        <div className="membership-title">
          <h1>
            You lost your way here, but here is a motivational quote for you: Go
            back!
          </h1>
        </div>
      </div>
    </section>
  );
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
