/* eslint-disable default-case */
import { AUTH } from "./constants";

const authState = {};

const reducer = (state = authState, action) => {
  switch (action.type) {
    case AUTH:
      state = { ...state, ...action.value };
      break;
  }
  return state;
};

export default reducer;
