/* eslint-disable default-case */
import { USER } from "./constants";

const userState = {};

const reducer = (state = userState, action) => {
  switch (action.type) {
    case USER:
      state = { ...state, ...action.value };
      break;
  }
  return state;
};

export default reducer;
