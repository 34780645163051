import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RequestResetPassword = ({ onSubmitHandler, onChangeHandler }) => {
  const { t } = useTranslation();

  return (
    <div className="row justify-content-end">
      <div className="col-md-7 col-lg-7 col-xl-6">
        <div className="login-wrapper">
          <div className="login-top">
            <p>{t("already_have_account")}</p>
            <Link to="/auth/login" className="button">
              Sign in
            </Link>
          </div>
          <div className="login-title">
            <h1>{t("request_reset_password")}</h1>
            <p>{t("enter_details")}</p>
          </div>
          <div className="login-form">
            <form onSubmit={onSubmitHandler} onChange={onChangeHandler} action="">
              <div className="login-input ge-input ge-input-password">
                <input
                  type="email"
                  className="form-control"
                  placeholder={t("email")}
                  name="email"
                  required
                />
              </div>
              <div className="login-submit">
                <input
                  type="submit"
                  className="button"
                  value={t("reset_password")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestResetPassword.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default RequestResetPassword;
