import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Aside from "../../components/Auth/Aside";
import Loading from "../../components/UI/Loading";
import Login from "../Login";
import Register from "../Register";
import RequestResetPassword from "../RequestResetPassword";

const AuthContainer = () => {
  const match = useRouteMatch();

  return (
    <section className="login">
      <Loading />
      <Aside />
      <div className="container">
        <Switch>
          <Route
            path={match.url + "/forgot-password"}
            component={RequestResetPassword}
          />
          <Route path={match.url + "/register"} component={Register} />
          <Route path={match.url} component={Login} />
        </Switch>
      </div>
    </section>
  );
};

export default AuthContainer;
