import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap4-modal";

import { MODAL_CHECK, MODAL_ERROR } from "../../../assets";

const ActionModalComponent = ({
  show,
  isError,
  message,
  cancelButton,
  actionButton,
  onCloseHandler,
  onSubmitHandler,
}) => {
  return (
    <Modal
      visible={show}
      className={"modal fade modal-general"}
      dialogClassName="modal-dialog"
      onClick={onCloseHandler}
    >
      <div className="modal-body">
        {isError ? <MODAL_ERROR /> : <MODAL_CHECK />}
        <p>{message}</p>
      </div>
      <div className="modal-footer">
        <button onClick={onCloseHandler} type="button" className="button">
          {cancelButton || "Cancel"}
        </button>
        <button onClick={onSubmitHandler} type="button" className="button">
          {actionButton || "OK"}
        </button>
      </div>
    </Modal>
  );
};

ActionModalComponent.propTypes = {
  onCloseHandler: PropTypes.func,
  onSubmitHandler: PropTypes.func,
  show: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.string,
  cancelButton: PropTypes.string,
  actionButton: PropTypes.string,
};

export default ActionModalComponent;
