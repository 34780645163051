export const PROFILE = `PROFILE`;
export const profile = value => {
  return {
    type: PROFILE,
    value,
  };
};

export const ADD_ERRORS = `ADD_ERRORS`;
export const addErrors = value => {
  return {
    type: ADD_ERRORS,
    value,
  };
};

export const RESET_ERROR_STATE = `RESET_ERROR_STATE`;
export const resetErrors = value => {
  return {
    type: RESET_ERROR_STATE,
    value,
  };
};
