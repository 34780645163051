import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ProfileBanner from "../../components/Profile/ProfileBanner";
import ProfileOverview from "../../components/Profile/Overview";

import { loadProfileExtended } from "./actions";

const OverviewContainer = () => {
  const user = useSelector(s => s.user);
  const dispatch = useDispatch();

  const shouldUpdate =
    user.followingStats === undefined ||
    user.visionBoard === undefined ||
    user.projects === undefined ||
    user.instagramFeed === undefined;

  console.log({ user, shouldUpdate })

  useEffect(() => {
    if (shouldUpdate) dispatch(loadProfileExtended());
  }, [dispatch, shouldUpdate]);

  if (shouldUpdate) return <ProfileBanner />;

  return (
    <React.Fragment>
      <ProfileBanner />
      <ProfileOverview />
    </React.Fragment>
  );
};

OverviewContainer.propTypes = {};

export default OverviewContainer;
