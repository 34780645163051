import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap4-modal";

import { MODAL_CHECK, MODAL_ERROR } from "../../../assets";

const ModalComponent = ({ show, isError, message, button, onCloseHandler }) => {
  return (
    <Modal
      visible={show}
      className={"modal fade modal-general"}
      dialogClassName="modal-dialog"
      onClick={onCloseHandler}
    >
      <div className="modal-body">
        {isError ? <MODAL_ERROR /> : <MODAL_CHECK />}
        <p>{message}</p>
      </div>
      <div className="modal-footer">
        <button onClick={onCloseHandler} type="button" className="button">
          {button || "OK"}
        </button>
      </div>
    </Modal>
  );
};

ModalComponent.propTypes = {
  onCloseHandler: PropTypes.func,
  show: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.string,
  button: PropTypes.string,
};

export default ModalComponent;
