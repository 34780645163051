import React, { useRef } from "react";
import PropTypes from "prop-types";
import ProjectFormBanner from "./Banner";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Step5 = ({ changePageHandler, onChangeHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);

  return (
    <form
      onChange={onChangeHandler}
      onSubmit={e => changePageHandler(form, e, "step-6")}
      ref={form}
    >
      <ProjectFormBanner
        submitHandler={(e, location) => changePageHandler(form, e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-content">
            <h1>{t("add_links_to_project")}</h1>
            <p>{t("step_5_content")}</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="projects-new-input ge-input">
            <input
              defaultValue={state.facebook_link}
              name="facebook_link"
              type="text"
              className="form-control"
              placeholder="Facebook"
            />
          </div>
          <div className="projects-new-input ge-input">
            <input
              defaultValue={state.instagram_link}
              name="instagram_link"
              type="text"
              className="form-control"
              placeholder="Instagram"
            />
          </div>
          <div className="projects-new-input ge-input">
            <input
              defaultValue={state.linkedin_link}
              name="linkedin_link"
              type="text"
              className="form-control"
              placeholder="LinkedIn"
            />
          </div>
          <div className="projects-new-input ge-input">
            <input
              defaultValue={state.other_link}
              name="other_link"
              type="text"
              className="form-control"
              placeholder={t("other")}
            />
          </div>
          <div className="projects-new-input ge-input">
            <input
              defaultValue={state.url}
              name="url"
              type="text"
              className="form-control"
              placeholder={t("website")}
            />
          </div>
          <div className="projects-new-input ge-send">
            <input name="" type="submit" value={t("continue")} className="button" />
          </div>
        </div>
      </div>
    </form>
  );
};

Step5.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Step5;
