import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import icons from "../../helpers/icons";

import {
  FACEBOOK_LOGO,
  INSTAGRAM_LOGO,
  LINKEDIN_LOGO,
  OTHER_LINK_LOGO,
} from "../../assets";

import DetailviewHeader from "./DetailviewHeader";
import Image from "../UI/Image";
import CommentsContainer from "../../containers/Comments";

const ProjectDetailview = ({ deleteProjectHandler }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const project = useSelector(state => state.projectState.project);
  const comments = project.comments;

  if (!project.id) return null;

  return (
    <Fragment>
      <DetailviewHeader
        edit={user.id === project.user.id}
        share
        title={project.title}
        id={project.id}
        deleteHandler={deleteProjectHandler}
      />

      <section className="projects-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-2">
              <div className="projects-detail-aside">
                <div className="category">
                  <h2>{t("category")}</h2>
                  <div className="projects-categories-list">
                    <ul>
                      {project.categories.map(category => (
                        <li key={category.id} className="active">
                          <a href="javascript:void(0);">
                            <i className={icons[+category.id - 1]}></i>
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="author">
                  <h2>{t("project_creator")}</h2>
                  <div className="author-wrap">
                    <div className="author-img">
                      <Image src={project.user.media_url} alt="creator profile" />
                    </div>
                    <div className="author-name">
                      <h1>{project.user.name}</h1>
                    </div>
                  </div>
                </div>
                <div className="date">
                  <h2>{t("created")}</h2>
                  <span>
                    <i className="icon-calendar"></i>
                    {project.created_at}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-10 col-xl-8">
              <div className="projects-detail-content">
                <div className="projects-detail-content-img">
                  <Image src={project.media_id} alt="projects" />
                </div>
                <p>{project.description}</p>
                <div className="projects-detail-content-gallery">
                  {project.images.map(record => (
                    <Image key={record.id} src={record.image} alt="gallery" />
                  ))}
                </div>
                <h2>{t("real_opportunity")}</h2>
                <p>{project.opportunity}</p>
                <h2>{t("value_propositions")}</h2>
                <p>{project.propositions}</p>
                <h2>{t("current_status")}</h2>
                <p>{project.current_status}</p>
                <div className="projects-links">
                  <h2>{t("project_links")}</h2>
                  <ul>
                    {!project.facebook_link ? null : (
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={project.facebook_link}
                        >
                          <FACEBOOK_LOGO />
                        </a>
                      </li>
                    )}
                    {!project.instagram_link ? null : (
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={project.instagram_link}
                        >
                          <INSTAGRAM_LOGO />
                        </a>
                      </li>
                    )}
                    {!project.linkedin_link ? null : (
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={project.linkedin_link}
                        >
                          <LINKEDIN_LOGO />
                        </a>
                      </li>
                    )}
                    {!project.other_link ? null : (
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={project.other_link}
                        >
                          <OTHER_LINK_LOGO />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <CommentsContainer projectId={project.id} comments={comments} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {!project.url ? null : (
        <footer className="footer-project nav-down">
          <div className="container">
            <div className="footer-project-website">
              <a target="_blank" rel="noopener noreferrer" href={project.url}>
                <i className="icon-world"></i>
                {t("visit_project_website")}
              </a>
            </div>
          </div>
        </footer>
      )}
    </Fragment>
  );
};

ProjectDetailview.propTypes = {
  deleteProjectHandler: PropTypes.func.isRequired,
};

export default ProjectDetailview;
