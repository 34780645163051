import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import get from "lodash/get";

import Header from "../../components/Projects/Header";
import Project from "../../components/Projects/Project";

import { getMyProjects } from "./actions";
import CategoriesContainer from "./Categories";

const MyProjectsContainer = () => {
  const { t } = useTranslation();
  const projectState = useSelector(s => s.projectState);
  const {
    myProjects: { pagination, data: projects },
  } = projectState;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (
      get(projectState, "myProjects.pagination", undefined) === undefined ||
      get(projectState, "myProjects.queryParams", "") !== location.search
    ) {
      setLoading(true);
      dispatch(getMyProjects(location.search, 1, true)).then(() =>
        setLoading(false)
      );
    }
  }, [dispatch, location.search, projectState]);

  const fetchMore = () => {
    if (pagination && pagination.current_page < pagination.last_page) {
      let { search } = location;
      dispatch(getMyProjects(search, pagination.current_page + 1));
    }
  };

  return (
    <div>
      <Header projectBoost />
      <section className="projects-categories">
        <div className="container">
          <div className="projects-categories-title">
            <h1>{t("categories")}</h1>
          </div>
          <div className="projects-categories-list">
            <CategoriesContainer />
          </div>
        </div>
      </section>
      <section className="projects mg-top-100">
        <div className="container">
          <div className="row">
            {(projects || []).map(project => (
              <Project project={project} key={project.id} />
            ))}
          </div>
          {loading ||
          !pagination ||
          pagination.current_page === pagination.last_page ? null : (
            <div className="col-md-12">
              <div className="load-more">
                <button onClick={fetchMore} type="button" className="button">
                  {t("load_more")}
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default MyProjectsContainer;
