import api from "../../api";
import { toggleLoading } from "../constants";

import { setUser } from "./constants";

export const loadProfile = () => async dispatch => {
  dispatch(toggleLoading(true));

  try {
    const response = await api.get("profile/my");
    if (response) {
      dispatch(setUser({ ...response.data.data }));
    }
  } catch (error) {
    console.log("====================================");
    console.log({ error });
    console.log("====================================");
    dispatch(setUser({ error: error.data.message }));
  }

  dispatch(toggleLoading(false));
};
