import React, { useRef } from "react";
import PropTypes from "prop-types";
import ProjectFormBanner from "./Banner";
import CategoriesContainer from "../../../containers/Projects/Categories";
import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../../../containers/constants";
import { useTranslation } from "react-i18next";

const Step2 = ({ changePageHandler, onChangeHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);
  const dispatch = useDispatch();

  const submitHandler = (e, location) => {
    if ((state.categories || []).length > 0) {
      changePageHandler(form, e, location);
    } else {
      e.preventDefault();
      dispatch(
        toggleModal({
          show: true,
          message: "Please select at least one category.",
          isError: true,
        })
      );
    }
  };

  return (
    <form ref={form}>
      <ProjectFormBanner
        submitHandler={(e, location) => submitHandler(e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-content">
            <h1>{t("chose_category")}</h1>
            <p>{t("step_2_content")}</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="category-select">
            <CategoriesContainer
              preSelected={state.categories || []}
              multiple={e =>
                onChangeHandler({ target: { name: "categories", value: [...e] } })
              }
            />
          </div>
          <div className="projects-new-input ge-send">
            <input
              onClick={e => submitHandler(e, "step-3")}
              type="submit"
              value="Continue"
              className="button"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

Step2.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Step2;
