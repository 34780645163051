import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import globalReducer from "../containers/reducers";
import authReducer from "../containers/Auth/reducers";
import projectReducer from "../containers/Projects/reducers";
import userReducer from "../containers/App/reducers";
import profileReducer from "../containers/Profile/reducers";

const reducers = combineReducers({
  projectState: projectReducer,
  authState: authReducer,
  globalState: globalReducer,
  profileState: profileReducer,
  user: userReducer,
});

const enhancers = [applyMiddleware(thunk)];

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

export const store = createStore(reducers, composeEnhancers(...enhancers));
