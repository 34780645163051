import axios from "axios";

import { getApiUrl, getToken, isAuth } from "../helpers/auth";

const api = axios.create({
  baseURL: getApiUrl(),

  headers: {
    "Content-Type": "application/json",
  },

  validateStatus: function (status) {
    switch (status) {
      case 200:
        return true;
      default:
        return true;
    }
  },

  responseType: "json",
});

api.interceptors.response.use(response => {
  switch (response.status) {
    case 200:
      return response;
    case 401:
    default:
      return Promise.reject({ data: response.data || {}, status: response.status });
  }
});

api.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: isAuth() ? "Bearer " + getToken() : "",
  },
}));

export default api;
