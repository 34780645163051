import React from "react";
import { Redirect, Route } from "react-router-dom";

import { getToken } from "../helpers/auth";

const PrivateRoute = props => {
  if (getToken()) return <Route {...props} />;

  return <Redirect to={"/auth/login"} />;
};

export default PrivateRoute;
