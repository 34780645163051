import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CommentProperties = ({
  authorId,
  onClickEdit,
  onClickDelete,
  onClickReport,
}) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  return (
    <div
      className="dropdown-menu dropdown-menu-right"
      aria-labelledby="commentsDropDown"
    >
      {user.id !== authorId ? (
        <a
          onClick={e => {
            e.preventDefault();
            onClickReport();
          }}
          className="dropdown-item"
          href="#"
        >
          {t("report")}
        </a>
      ) : (
        <Fragment>
          <a
            onClick={e => {
              e.preventDefault();
              onClickDelete();
            }}
            className="dropdown-item"
            href="#"
          >
            {t("delete")}
          </a>
          <a
            onClick={e => {
              e.preventDefault();
              onClickEdit();
            }}
            className="dropdown-item"
            href="#"
          >
            {t("edit")}
          </a>
        </Fragment>
      )}
    </div>
  );
};

CommentProperties.propTypes = {
  authorId: PropTypes.number.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickReport: PropTypes.func.isRequired,
};

export default CommentProperties;
