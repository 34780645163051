import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import LoginForm from "../../components/Auth/LoginForm";

import { authenticate } from "./actions";

import { EMAIL_REGEX } from "../../helpers/regex";

const LoginContainer = () => {
  const [state, setState] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   *
   * @param {Event} e
   */
  const formHandler = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  /**
   *
   * @param {Event} e
   */
  const loginHandler = e => {
    e.preventDefault();

    let data = { ...state };

    if (!EMAIL_REGEX.test(data.email.toLowerCase())) {
      data.username = state.email;
      delete data.email;
    }

    dispatch(authenticate(data)).then(() => history.push("/"));
  };

  return (
    <LoginForm loginHandler={loginHandler} formHandler={formHandler} state={state} />
  );
};

LoginContainer.propTypes = {};

export default LoginContainer;
