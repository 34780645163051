import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LoadMore = ({ action }) => {
  const { t } = useTranslation();
  return (
    <div className="col-md-12">
      <div className="load-more">
        <button onClick={action} type="button" className="button">
          {t("load_more")}
        </button>
      </div>
    </div>
  );
};

LoadMore.propTypes = {
  action: PropTypes.func.isRequired,
};

export default LoadMore;
