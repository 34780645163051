import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Image from "../UI/Image";

const Project = ({ project }) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-6 col-lg-4">
      <div className="projects-item">
        <div className="projects-item-img">
          <Image src={project.media_id} alt="projects" />
        </div>
        <div className="projects-item-title">
          <h1>{project.title}</h1>
        </div>
        <div className="projects-author">
          <div className="projects-author-wrap">
            <div className="projects-author-img">
              <Link to="# ">
                <img src={project.user.media_url} alt="author" />
              </Link>
            </div>
            <Link to="# " className="projects-author-info">
              <h2>{project.user.name}</h2>
              <p>{t("project_creator")}</p>
            </Link>
          </div>
          <span className="date">{project.created_at}</span>
        </div>
        <div className="projects-item-content">
          <p>{project.description}</p>
          <Link to={"/projects/" + project.id} className="more">
            {t("read_more")}
          </Link>
        </div>
      </div>
    </div>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Project;
