import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ThirdPartyAuthContainer from "../../containers/ThirdPartyAuth";
import { WRONG } from "../../assets";

const LoginForm = ({ loginHandler, formHandler, state }) => {
  const { error } = useSelector(s => s.authState);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="row justify-content-end">
      <div className="col-md-7 col-lg-7 col-xl-6">
        <div className="login-wrapper">
          <div className="login-top">
            <p>{t("dont_have_account")}</p>
            <Link to="/auth/register" className="button">
              {t("register")}
            </Link>
          </div>
          <div className="login-title">
            <h1>{t("sign_in_to_account")}</h1>
            <p>{t("enter_details")}</p>
          </div>
          <div className="login-form">
            <form
              onChange={formHandler}
              onSubmit={loginHandler}
              autoComplete="new-password"
              action=""
            >
              {!error ? null : (
                <div className="login-error">
                  <WRONG />
                  <span>{error || ""}</span>
                </div>
              )}
              <div className="login-input ge-input">
                <input
                  type="text"
                  defaultValue={(state && state.email) || ""}
                  autoComplete="new-password"
                  className="form-control"
                  placeholder={t("username")}
                  name="email"
                  required
                />
              </div>
              <div className="login-input ge-input ge-input-password">
                <input
                  autoComplete="new-password"
                  id="password-field"
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  placeholder={t("password")}
                  required
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className={
                    "toggle-password " + (showPassword ? "pass-eye" : "pass-eye-off")
                  }
                ></span>
              </div>
              <div className="login-submit">
                <input type="submit" className="button" value={t("sign_in")} />
                <Link to="/auth/forgot-password" className="forgot">
                  {t("forgot_your_password")}
                </Link>
              </div>
            </form>
          </div>
          <ThirdPartyAuthContainer />
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  loginHandler: PropTypes.func.isRequired,
  formHandler: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

export default LoginForm;
