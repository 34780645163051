import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ARROW_BACK, SHARE, DELETE, EDIT } from "../../assets";

const DetailviewHeader = ({ title, share, edit, id, deleteHandler }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  return (
    <section className="projects-detail-banner">
      <div className="container">
        <div className="projects-detail-back">
          <Link
            to={
              match.path.includes("my") || match.path.includes("boost")
                ? "/projects/my"
                : "/projects"
            }
          >
            <ARROW_BACK />
            {t("back")}
          </Link>
        </div>
        <div className="projects-detail-top">
          <h2>{title}</h2>
          <ul className="project-tool">
            {!edit ? null : (
              <Fragment>
                <li>
                  <Link to={`${id}/update/step-1`}>
                    <EDIT />
                  </Link>
                </li>
                <li className="delete">
                  <a onClick={deleteHandler} href="">
                    <DELETE />
                  </a>
                </li>
              </Fragment>
            )}
            {!share ? null : (
              <li>
                <a href="javascript:void(0);">
                  <SHARE />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

DetailviewHeader.propTypes = {
  deleteHandler: PropTypes.func,
  share: PropTypes.bool,
  edit: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.number,
};

export default DetailviewHeader;
