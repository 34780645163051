import React from "react";
import Modal from "react-bootstrap4-modal";

import { MODAL_CHECK, MODAL_ERROR } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../../containers/constants";

const GlobalModalComponent = () => {
  const dispatch = useDispatch();
  let modal = useSelector(state => state.globalState.modal);

  let closeModal = () => dispatch(toggleModal({ show: false }));

  return (
    <Modal
      visible={modal.show}
      className={"modal fade modal-general"}
      dialogClassName="modal-dialog"
      onClick={closeModal}
    >
      <div className="modal-body">
        {modal.isError ? <MODAL_ERROR /> : <MODAL_CHECK />}
        <p>{modal.message}</p>
      </div>
      <div className="modal-footer">
        <button onClick={closeModal} type="button" className="button">
          {modal.button || "OK"}
        </button>
      </div>
    </Modal>
  );
};

export default GlobalModalComponent;
