import api from "../../api";

import { toggleLoading } from "../constants";
import { setUser } from "../App/constants";
import { auth } from "../Auth/constants";

import { setToken } from "../../helpers/auth";

export const authenticate = data => async dispatch => {
  dispatch(toggleLoading(true));

  try {
    const response = await api.post("login", data);
    if (response) {
      setToken(response.data.data.token);
      dispatch(setUser({ ...response.data.data.user }));
    }
  } catch (error) {
    dispatch(auth({ error: error.data.message }));
  }

  dispatch(toggleLoading(false));
};
