import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CommentInput from "./CommentInput";
import { useTranslation } from "react-i18next";

const ReplyForm = ({ comment, submitHandler }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  return (
    <div className="comments-input comments-input-r">
      <div className="comments-input-r-inner">
        <div className="comments-img-r">
          <img src={user.media_url} alt="" />
        </div>
        <CommentInput
          placeholder={t("leave_reply")}
          submitHandler={text => submitHandler(comment.id, text)}
        />
      </div>
    </div>
  );
};

ReplyForm.propTypes = {
  comment: PropTypes.object.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default ReplyForm;
