import React from "react";
import { useTranslation } from "react-i18next";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import PropTypes from "prop-types";

import { FACEBOOK_THIRD_PARTY_LOGO, GOOGLE_LOGO } from "../../assets";

const ThirdPartyAuth = ({ fbAuthHandler, gpAuthHandler }) => {
  const { t } = useTranslation();

  return (
    <div className="login-social">
      <p>{t("sign_in_with_social_media")}</p>
      <ul>
        <li>
          <FacebookLogin
            appId={"331970534144228"}
            callback={fbAuthHandler}
            fields="name,email,picture"
            render={fProps => (
              <a
                onClick={e => {
                  e.preventDefault();
                  fProps.onClick();
                }}
                href="javascript:void(0);"
              >
                <FACEBOOK_THIRD_PARTY_LOGO />
                {t("sign_in_with_social_media_type", { name: "Facebook" })}
              </a>
            )}
          />
        </li>
        <li>
          <GoogleLogin
            clientId="432318974924-qe24hopeaslacmp5dktfqs8svo8ed4hs.apps.googleusercontent.com"
            render={gProps => (
              <a onClick={gProps.onClick} href="javascript:void(0);">
                <GOOGLE_LOGO />
                {t("sign_in_with_social_media_type", { name: "Google" })}
              </a>
            )}
            buttonText="Login"
            onSuccess={gpAuthHandler}
            onFailure={gpAuthHandler}
            cookiePolicy={"single_host_origin"}
          />
        </li>
      </ul>
    </div>
  );
};

ThirdPartyAuth.propTypes = {
  fbAuthHandler: PropTypes.func.isRequired,
  gpAuthHandler: PropTypes.func.isRequired,
};

export default ThirdPartyAuth;
