import React from "react";
import { Switch, Route } from "react-router-dom";

import OverviewContainer from "./OverviewContainer";
import SettingsContainer from "./SettingsContainer";

// import { loadProfileExtended } from "./actions";
import ProfileBanner from "../../components/Profile/ProfileBanner";

const ProfileContainer = () => {
  return (
    <Switch>
      <Route exact path="/profile" component={OverviewContainer} />
      <Route exact path="/profile/settings" component={SettingsContainer} />
      <Route exact path="/profile/edit-profile" component={SettingsContainer}>
        <React.Fragment>
          <ProfileBanner />
        </React.Fragment>
      </Route>
      <Route exact path="/profile/membership" component={SettingsContainer}>
        <React.Fragment>
          <ProfileBanner />
        </React.Fragment>
      </Route>
      <Route exact path="/profile/order-history" component={SettingsContainer}>
        <React.Fragment>
          <ProfileBanner />
        </React.Fragment>
      </Route>
    </Switch>
  );
};

export default ProfileContainer;
