import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Image = ({ src, ...args }) => {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (typeof src === "object") {
      const blob = URL.createObjectURL(src);
      setPreview(blob);
    } else {
      setPreview(src);
    }
    return () => {
      if (typeof src === "object") {
        URL.revokeObjectURL(preview);
      }
    };
  }, [src]);

  return <img alt="none" {...args} src={preview} />;
};

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Image;
