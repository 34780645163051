import React from "react";
import { Redirect, Route } from "react-router-dom";

import { getToken } from "../helpers/auth";

const NoAuthRoute = props => {
  if (!getToken()) return <Route {...props} />;
  return <Redirect to={"/"} />;
};

export default NoAuthRoute;
