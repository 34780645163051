import React, { useRef } from "react";
import PropTypes from "prop-types";

import { BLACK_PLUS_SIGN } from "../../../assets";
import ProjectFormBanner from "./Banner";
import { useSelector } from "react-redux";
import Image from "../../UI/Image";
import { useTranslation } from "react-i18next";

const Step4 = ({ changePageHandler, onChangeHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);

  return (
    <form onSubmit={e => changePageHandler(form, e, "step-5")} ref={form}>
      <ProjectFormBanner
        submitHandler={(e, location) => changePageHandler(form, e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-content">
            <h1>{t("choose_image_for_project")}</h1>
            <p>{t("step_4_content")}</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="projects-new-upload ge-upload">
            <input
              required={!state.media_id}
              type="file"
              onChange={onChangeHandler}
              className="file-upload-input"
              accept="image/*"
            />
            {state.media_id ? (
              <div className="projects-new-gallery">
                <ul>
                  <li className="image">
                    <Image src={state.media_id} />
                  </li>
                </ul>
              </div>
            ) : (
              <div className="icon">
                <BLACK_PLUS_SIGN />
              </div>
            )}
          </div>
          <div className="projects-new-input ge-send">
            <input type="submit" value={t("continue")} className="button" />
          </div>
        </div>
      </div>
    </form>
  );
};

Step4.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Step4;
