import api from "../../api";
import { toggleLoading } from "../constants";
import { executeAllPromises } from "../../helpers/PromiseAllSettled.js";

import { setUser } from "../App/constants";
import { profile } from "./constants";

/**
 * api.get(`users/${data.id}/memberships`),
      api.get(`shop/orders`),
 */

export const loadProfileExtended = () => async (dispatch, getState) => {
  dispatch(toggleLoading(true));
  let { user } = getState();

  try {
    let resp = {};
    let promises = [
      api.get(`users/${user.id}/followings/stats`),
      api.get(`users/${user.id}/visionboard`),
      api.get(`my_projects`),
    ];

    if (user.has_instagram_connected)
      promises.push(api.get(`users/${user.id}/instagram`));

    let [
      followingStats,
      visionBoard,
      projects,
      instagramFeed,
    ] = await executeAllPromises(promises);

    resp.followingStats = followingStats;

    resp.visionBoard = visionBoard;

    resp.projects = projects;

    resp.instagramFeed = instagramFeed || [];

    dispatch(setUser({ ...resp }));
  } catch (error) {
    dispatch(setUser({ error: error.data.message }));
  }

  dispatch(toggleLoading(false));
};

export const checkUsername = username => async dispatch => {
  if (!username) return false;
  try {
    const response = await api.get("users/username/" + username);
    if (response) return response.data.data.is_available;
    return false;
  } catch (error) {
    dispatch(profile({ error: error.data.message }));
  }
};

export const editUsernameAction = ({ username }) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const response = await api.put("profile/my", { username });
    if (response) {
      dispatch(setUser({ ...response.data.data }));
      return true;
    }
  } catch (error) {
    throw error.data.message;
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const changePassword = ({
  current_password,
  new_password,
}) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const response = await api.post("accounts/change_password", {
      current_password,
      new_password,
    });
    if (response) return true;
    return false;
  } catch (error) {
    throw error.data.message;
  } finally {
    dispatch(toggleLoading(false));
  }
};
