import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { PLUS_SIGN, BOOST_ICON } from "../../assets";
import { prepareProjectCreate } from "../../containers/Projects/constants";

const Header = ({ projectBoost }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <section className="projects-banner">
      <div className="container">
        <div className="projects-banner-wrap">
          <div className="projects-banner-title">
            <h1>{t("projects")}</h1>
          </div>
          <div className="projects-nav">
            <ul>
              <li>
                <NavLink exact to="/projects">
                  {t("discover")}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/projects/my">
                  {t("my_project")}
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/projects/about">
                  {t("about_projects")}
                </NavLink>
              </li>
            </ul>
          </div>

          {!projectBoost ? null : (
            <div className="boost-project">
              <Link to="/projects/projects-boost">
                <BOOST_ICON />
                {t("boost_project")}
              </Link>
            </div>
          )}
          <div className="add-project">
            <Link
              onClick={() => dispatch(prepareProjectCreate())}
              to="/projects/new/step-1"
            >
              <PLUS_SIGN />
              {t("create_new_project")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  projectBoost: PropTypes.bool,
};

export default Header;
