import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { removeToken } from "../../helpers/auth";
import { APP_LOGO } from "../../assets";

const Header = () => {
  const { t } = useTranslation();
  const user = useSelector(s => s.user);

  /**
   *
   * @param {Event} e
   */
  const logOutHandler = e => {
    e.preventDefault();
    removeToken();
    window.location.href = "/auth/login";
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header-wrap">
          <div className="header-left">
            <div className="header-logo">
              <Link to="/">
                <APP_LOGO />
              </Link>
            </div>
            <div className="header-menu">
              <ul>
                <li>
                  <NavLink exact to="/">
                    <i className="icon-dashboard"></i>
                    {t("dashboard")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/projects">
                    <i className="icon-projects"></i> {t("projects")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/profile">
                    <i className="icon-profile"></i> {t("profile")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="header-right">
            <div className="header-profile">
              <div className="header-profile-img">
                <img src={user && user.media_url} alt="profile" />
              </div>
              <a href="javascript:void(0);" onClick={logOutHandler}>
                {t("sign_out")}
              </a>
            </div>
          </div>
          <button className="main-menu-indicator visible-xs-block" id="open-menu">
            <span className="line"></span>
          </button>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
