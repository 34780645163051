import React, { useRef } from "react";
import PropTypes from "prop-types";
import ProjectFormBanner from "./Banner";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Step3 = ({ changePageHandler, onChangeHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);

  return (
    <form
      onChange={onChangeHandler}
      onSubmit={e => changePageHandler(form, e, "step-4")}
      ref={form}
    >
      <ProjectFormBanner
        submitHandler={(e, location) => changePageHandler(form, e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="projects-new-input projects-new-input-s3 ge-input">
            <h2>{t("real_opportunity")}</h2>
            <textarea
              name="opportunity"
              className="form-control"
              placeholder={t("real_opportunity")}
              maxLength={700}
              minLength={20}
              defaultValue={state.opportunity}
              required
            ></textarea>
            <span className="info">{t("no_characters", { num: "20-700" })}</span>
          </div>
          <div className="projects-new-input projects-new-input-s3 ge-input">
            <h2>{t("value_propositions")}</h2>
            <textarea
              name="propositions"
              className="form-control"
              placeholder={t("your_solution")}
              maxLength={700}
              minLength={20}
              defaultValue={state.propositions}
              required
            ></textarea>
            <span className="info">{t("no_characters", { num: "20-700" })}</span>
          </div>
          <div className="projects-new-input projects-new-input-s3 ge-input">
            <h2>{t("current_status")}</h2>
            <textarea
              name="current_status"
              className="form-control"
              placeholder={t("your_status")}
              maxLength={700}
              minLength={20}
              defaultValue={state.current_status}
              required
            ></textarea>
            <span className="info">{t("no_characters", { num: "20-700" })}</span>
          </div>
          <div className="projects-new-input ge-send">
            <input type="submit" value={t("continue")} className="button" />
          </div>
        </div>
      </div>
    </form>
  );
};

Step3.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Step3;
