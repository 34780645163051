export const SET_CATEGORIES = `SET_CATEGORIES`;
export const setCategories = value => {
  return { type: SET_CATEGORIES, value };
};

export const SET_INTERESTS = `SET_INTERESTS`;
export const setInterests = value => {
  return { type: SET_INTERESTS, value };
};

export const SET_PROJECTS = `SET_PROJECTS`;
export const setProjects = value => {
  return { type: SET_PROJECTS, value };
};

export const ADD_PROJECTS = `ADD_PROJECTS`;
export const addProjects = value => {
  return { type: ADD_PROJECTS, value };
};

export const DELETE_PROJECT = `DELETE_PROJECT`;
export const deleteProject = value => {
  return { type: DELETE_PROJECT, value };
};

export const SET_MY_PROJECTS = `SET_MY_PROJECTS`;
export const setMyProjects = value => {
  return { type: SET_MY_PROJECTS, value };
};

export const ADD_MY_PROJECTS = `ADD_MY_PROJECTS`;
export const addMyProjects = value => {
  return { type: ADD_MY_PROJECTS, value };
};

export const PROJECT_DETAILVIEW = `PROJECT_DETAILVIEW`;
export const addProjectDetailview = value => {
  return { type: PROJECT_DETAILVIEW, value };
};

export const FETCH_MORE_PROJECT_COMMENTS = `FETCH_MORE_PROJECT_COMMENTS`;
export const fetchMoreProjectComments = value => {
  return { type: FETCH_MORE_PROJECT_COMMENTS, value };
};

export const ADD_PROJECT_COMMENT = `ADD_PROJECT_COMMENT`;
export const addProjectComment = value => {
  return { type: ADD_PROJECT_COMMENT, value };
};

export const EDIT_PROJECT_COMMENT = `EDIT_PROJECT_COMMENT`;
export const editProjectComment = value => {
  return { type: EDIT_PROJECT_COMMENT, value };
};

export const DELETE_PROJECT_COMMENT = `DELETE_PROJECT_COMMENT`;
export const deleteProjectComment = value => {
  return { type: DELETE_PROJECT_COMMENT, value };
};

export const ADD_REPLY_TO_PROJECT_COMMENT = `ADD_REPLY_TO_PROJECT_COMMENT`;
export const addReplyToProjectComment = value => {
  return { type: ADD_REPLY_TO_PROJECT_COMMENT, value };
};

export const EDIT_REPLY_OF_PROJECT_COMMENT = `EDIT_REPLY_OF_PROJECT_COMMENT`;
export const editReplyOfProjectComment = value => {
  return { type: EDIT_REPLY_OF_PROJECT_COMMENT, value };
};

export const DELETE_REPLY_OF_PROJECT_COMMENT = `DELETE_REPLY_OF_PROJECT_COMMENT`;
export const deleteReplyOfProjectComment = value => {
  return { type: DELETE_REPLY_OF_PROJECT_COMMENT, value };
};

export const THX_PROJECT_COMMENT = `THX_PROJECT_COMMENT`;
export const thxProjectComment = value => {
  return { type: THX_PROJECT_COMMENT, value };
};

export const PREPARE_PROJECT_UPDATE = `PREPARE_PROJECT_UPDATE`;
export const prepareProjectUpdate = value => {
  return { type: PREPARE_PROJECT_UPDATE, value };
};

export const UPDATE_PROJECT = `UPDATE_PROJECT`;
export const updateProject = value => {
  return { type: UPDATE_PROJECT, value };
};

export const PREPARE_PROJECT_CREATE = `PREPARE_PROJECT_CREATE`;
export const prepareProjectCreate = value => {
  return { type: PREPARE_PROJECT_CREATE, value };
};

export const PROJECT_CREATED = `PROJECT_CREATED`;
export const projectCreated = value => {
  console.log({ value });

  return { type: PROJECT_CREATED, value };
};

export const MUTATE_PROJECT = `MUTATE_PROJECT`;
export const mutateProject = value => {
  return { type: MUTATE_PROJECT, value };
};
