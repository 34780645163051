import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import DetailviewHeader from "../../components/Projects/DetailviewHeader";
import BoostProjectForm from "../../components/Projects/BoostProjectForm";
import Modal from "../../components/UI/Modal";

import { projectBoostCategories, boostProjectAction } from "./actions";
import { toggleModal } from "../constants";

const ProjectBoost = () => {
  const { t } = useTranslation();
  const projects = useSelector(s => s.user.projects);
  const [state, setState] = useState({ boost_categories: [] });
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(projectBoostCategories(projects)).then(resp => setCategories(resp));
  }, [dispatch, projects]);

  /**
   *
   * @param {Event} e
   */
  const onChangeHandler = e => {
    const { value, name, type } = e.target;
    if (type === "checkbox") return onInterestsChange(+name.split("-")[1]);
    setState({ ...state, [name]: value });
  };

  /**
   *
   * @param {Number} id
   */
  const onInterestsChange = id => {
    let boost_categories = [...state.boost_categories];
    if (boost_categories.indexOf(id) > 0) {
      let values = boost_categories.filter(interest => interest !== id);
      boost_categories = [...values];
    } else {
      boost_categories.push(id);
    }
    setState({ ...state, boost_categories });
  };

  /**
   *
   * @param {Event} e
   */
  const onSubmitHandler = e => {
    e.preventDefault();

    if (!state.project || !+state.project)
      return dispatch(
        toggleModal({
          isError: true,
          message: t("please_select_a_project"),
          show: true,
        })
      );
    if (!state.boost_categories || state.boost_categories.length < 1)
      return dispatch(
        toggleModal({
          isError: true,
          message: t("please_select_at_least_an_interest"),
          show: true,
        })
      );
    if (!state.text || state.text.length < 20)
      return dispatch(
        toggleModal({
          isError: true,
          message: t("please_write_a_message"),
          show: true,
        })
      );

    dispatch(boostProjectAction(state)).then(() => setModal(true));
  };

  return (
    <Fragment>
      <DetailviewHeader title={t("project_boost")} />
      {categories.length === 0 ? null : (
        <BoostProjectForm
          projects={projects || []}
          categories={categories}
          onChangeHandler={onChangeHandler}
          onSubmitHandler={onSubmitHandler}
          state={state}
        />
      )}
      <Modal
        message={t("success")}
        show={modal}
        onCloseHandler={() => history.push("/projects/my")}
      />
    </Fragment>
  );
};

export default ProjectBoost;
