export const TOGGLE_LOADING = `TOGGLE_LOADING`;
export const toggleLoading = value => {
  return {
    type: TOGGLE_LOADING,
    value,
  };
};

export const TOGGLE_MODAL = `TOGGLE_MODAL`;
export const toggleModal = value => {
  return {
    type: TOGGLE_MODAL,
    value,
  };
};
