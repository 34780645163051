import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MentionsInput, Mention } from "react-mentions";
import { debounce } from "lodash";

import api from "../../api";
import { MENTION_REGEX } from "../../helpers/regex";

const CommentInput = ({ submitHandler, placeholder, value }) => {
  const { t } = useTranslation();
  let suggestionsPortalHost = useRef(null);
  const [text, setText] = useState(value || "");

  let onChange = e => {
    let { value } = e.target;
    setText(value);
  };

  let renderSuggestion = (suggestion, _, __, ___, focused) => (
    <span
      style={{
        backgroundColor: focused ? "#88cd46" : "transparent",
        color: focused ? "#fff" : "#000",
      }}
    >
      {suggestion.display}
    </span>
  );

  let getUsersByUsername = (query, callback) => {
    if (!query) return;
    api
      .get(`search?search_type=users&q=${query}`)
      .then(({ data: { data } }) => {
        return data.users.data.map(user => ({
          id: user.id,
          display: user.username,
        }));
      })
      .then(callback);
  };

  const debounceSearch = useRef(debounce(getUsersByUsername, 300));

  let onMentionTrigger = (query, callback) => {
    if (!query) return;
    debounceSearch.current(query, callback);
  };

  let onSubmitHandler = () => {
    let match;
    let value = text;
    while ((match = MENTION_REGEX.exec(text)) !== null) {
      value = value.replace(match[0], "@" + match[1]);
    }
    submitHandler(value).then(() => setText(""));
  };

  return (
    <div className="comments-input">
      <div className="comments-input-wrap">
        <MentionsInput
          value={text}
          onChange={onChange}
          placeholder={placeholder || ""}
          style={{ position: "unset" }}
          suggestionsPortalHost={suggestionsPortalHost.current}
        >
          <Mention
            displayTransform={(_, display) => `@${display}`}
            trigger="@"
            renderSuggestion={renderSuggestion}
            data={onMentionTrigger}
            markup={"@[__display__]"}
          />
        </MentionsInput>
        <div className="comments-suggestions">
          <ul ref={suggestionsPortalHost}></ul>
        </div>
      </div>
      <input
        onClick={onSubmitHandler}
        // onClick={() => submitHandler(text).then(() => setText(""))}
        type="submit"
        className="button"
        value={t("send")}
      />
    </div>
  );
};

CommentInput.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  edit: PropTypes.bool,
};

export default CommentInput;
