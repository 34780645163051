export default [
  "icon-dashboard",
  "icon-light",
  "icon-tech",
  "icon-world",
  "icon-heart",
  "icon-speaker",
  "icon-lighting",
  "icon-book",
  "icon-share",
];
