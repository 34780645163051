import React, { useRef } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

const DebouncedInput = ({
  isCorrect,
  changeHandler,
  value,
  name,
  required,
  placeholder,
  inputClassName,
  divClassName,
  type,
  debounceInterval,
  ...props
}) => {
  const debounceSearch = useRef(
    debounce(value => {
      changeHandler(value);
    }, debounceInterval || 300)
  );

  /**
   * @param {Event} e
   */
  const onChangeHandler = e => {
    e.preventDefault();
    debounceSearch.current(e.target.value);
  };

  return (
    <div
      className={
        (divClassName || "login-input ge-input ") +
        (isCorrect === undefined
          ? ""
          : isCorrect
          ? "ge-input-valid"
          : "ge-input-invalid")
      }
    >
      <input
        type={type || "text"}
        className={inputClassName || "form-control"}
        placeholder={placeholder}
        defaultValue={value || ""}
        name={name || "input"}
        required={required}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  );
};

DebouncedInput.propTypes = {
  isCorrect: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  inputClassName: PropTypes.string,
  divClassName: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  debounceInterval: PropTypes.number,
};

export default DebouncedInput;
