import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import CommentItem from "../../components/Comments/CommentItem";
import CommentInput from "../../components/Comments/CommentInput";
import LoadMore from "../../components/UI/LoadMore";

import {
  commentInProjectAction,
  getMoreProjectComments,
  thxProjectCommentAction,
  replyToProjectCommentAction,
  editProjectCommentAction,
  editReplyOfProjectCommentAction,
  deleteProjectCommentAction,
  deleteReplyOfProjectCommentAction,
  reportProjectCommentAction,
} from "./actions";
import { toggleModal } from "../constants";

const CommentsContainer = ({ projectId, comments }) => {
  const dispatch = useDispatch();
  const { total, current_page, item_per_page, extra, last_page } = get(
    comments,
    "pagination",
    {}
  );

  let currentDisplaying = item_per_page * current_page + (extra || 0);

  const commentHandler = text => {
    return dispatch(commentInProjectAction({ text, projectId }));
  };

  const editCommentHandler = (commentId, text) => {
    return dispatch(editProjectCommentAction({ text, commentId, projectId }));
  };

  const deleteCommentHandler = commentId => {
    return dispatch(deleteProjectCommentAction({ commentId, projectId }));
  };
  const replyHandler = (commentId, text) => {
    return dispatch(replyToProjectCommentAction({ text, commentId }));
  };

  const editReplyHandler = (commentId, replyId, text) => {
    return dispatch(editReplyOfProjectCommentAction({ commentId, replyId, text }));
  };

  const deleteReplyHandler = (commentId, replyId) => {
    return dispatch(deleteReplyOfProjectCommentAction({ commentId, replyId }));
  };

  const thxCommentHandler = (e, thank, commentId) => {
    e.preventDefault();
    return dispatch(thxProjectCommentAction({ thank, commentId }));
  };

  const reportCommentHandler = commentId => {
    return dispatch(reportProjectCommentAction({ commentId })).then(() => {
      dispatch(
        toggleModal({ show: true, message: "Comment/Reply reported successfully" })
      );
    });
  };

  const loadMoreCommentsHandler = () => {
    if (current_page < last_page) {
      dispatch(getMoreProjectComments({ projectId, page: current_page + 1 }));
    }
  };

  return (
    <div className="comments">
      <h2>
        Comments{" "}
        {
          <span>
            {currentDisplaying > total
              ? total + "/" + total
              : currentDisplaying + "/" + total}
          </span>
        }
      </h2>
      <CommentInput placeholder={"Leave a comment"} submitHandler={commentHandler} />
      <div className="comments-item">
        <ul>
          {get(comments, "data", []).map(comment => (
            <CommentItem
              key={comment.id}
              comment={comment}
              thxCommentHandler={thxCommentHandler}
              reportCommentHandler={reportCommentHandler}
              replyHandler={replyHandler}
              editCommentHandler={editCommentHandler}
              editReplyHandler={editReplyHandler}
              deleteCommentHandler={deleteCommentHandler}
              deleteReplyHandler={deleteReplyHandler}
            />
          ))}
        </ul>
      </div>
      {current_page === last_page ? null : (
        <LoadMore action={loadMoreCommentsHandler} />
      )}
    </div>
  );
};

CommentsContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  comments: PropTypes.object.isRequired,
};

export default CommentsContainer;
