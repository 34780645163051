import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useRouteMatch } from "react-router-dom";

import { TICK_FORM, THREE_DOTS } from "../../../assets";
import { useTranslation } from "react-i18next";

const ProjectFormBanner = ({ submitHandler }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const match = useRouteMatch();

  useEffect(() => {
    let url = match.url.split("-").pop();
    setStep(+url);
  }, [match.url]);

  const titles = [
    t("step", { no: 1 }),
    t("step", { no: 2 }),
    t("step", { no: 3 }),
    t("step", { no: 4 }),
    t("step", { no: 5 }),
    t("step", { no: 6 }),
    t("step", { no: 7 }),
  ];

  const setActive = (index, isIcon = false) => {
    if (step === index) return !isIcon ? "active" : <THREE_DOTS />;
    if (step > index) return !isIcon ? "done" : <TICK_FORM />;
    return !isIcon ? "" : null;
  };

  const onCLickHandler = (e, index) =>
    step >= index + 1 ? e : submitHandler(e, `step-${index + 1}`);

  return (
    <div className="row justify-content-center">
      <div className="projects-wizard">
        <ul>
          {titles.map((title, index) => (
            <li key={index} className={setActive(index + 1)}>
              <Link onClick={e => onCLickHandler(e, index)} to={`step-${index + 1}`}>
                <span className="icon">{setActive(index + 1, true)}</span>
                <span className="title">{title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ProjectFormBanner.propTypes = {
  submitHandler: PropTypes.func.isRequired,
};

export default ProjectFormBanner;
