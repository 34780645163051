import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import Reply from "./Reply";
import ReplyForm from "./ReplyForm";
import Comment from "./Comment";

const CommentItem = ({
  comment,
  thxCommentHandler,
  replyHandler,
  reportCommentHandler,
  editCommentHandler,
  editReplyHandler,
  deleteCommentHandler,
  deleteReplyHandler,
}) => {
  const {
    id,
    is_liked,
    user: { media_url },
  } = comment;

  const [reply, setReply] = useState(false);

  const thxHandler = e => thxCommentHandler(e, !is_liked, id);

  return (
    <li key={id}>
      <div className="comments-item">
        <div className="comments-img">
          <img src={media_url} alt="user profile pic" />
        </div>
        <Comment
          thxHandler={thxHandler}
          reportCommentHandler={reportCommentHandler}
          editCommentHandler={editCommentHandler}
          deleteCommentHandler={deleteCommentHandler}
          comment={comment}
          toggleReply={() => setReply(val => !val)}
        />
      </div>
      <div className="comments-item-r">
        <ul>
          {get(comment, "replies", []).map(reply => (
            <Reply
              editReplyHandler={(replyId, text) =>
                editReplyHandler(id, reply.id, text)
              }
              reportReplyHandler={reportCommentHandler}
              deleteReplyHandler={() => deleteReplyHandler(id, reply.id)}
              key={reply.id}
              reply={reply}
            />
          ))}
        </ul>
      </div>
      {!reply ? null : <ReplyForm submitHandler={replyHandler} comment={comment} />}
    </li>
  );
};

CommentItem.propTypes = {
  comment: PropTypes.object.isRequired,
  thxCommentHandler: PropTypes.func.isRequired,
  replyHandler: PropTypes.func.isRequired,
  editCommentHandler: PropTypes.func.isRequired,
  editReplyHandler: PropTypes.func.isRequired,
  deleteCommentHandler: PropTypes.func.isRequired,
  deleteReplyHandler: PropTypes.func.isRequired,
  reportCommentHandler: PropTypes.func.isRequired,
};

export default CommentItem;
