import api from "../../api";
import { toggleLoading } from "../constants";
import {
  setCategories,
  setInterests,
  setProjects,
  addProjects,
  setMyProjects,
  addMyProjects,
  addProjectDetailview,
  fetchMoreProjectComments,
  addProjectComment,
  updateProject,
  projectCreated,
  deleteProject,
} from "./constants";
import { setUser } from "../App/constants";

export const getProjectCategories = () => async dispatch => {
  try {
    let data = await api.get("projects/categories");
    dispatch(setCategories(data.data.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getProjectInterests = () => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    let data = await api.get("projects/interests");
    dispatch(setInterests(data.data.data));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getProjects = (
  queryParams,
  page,
  refreshState = false
) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    let qs = !queryParams ? "?" : queryParams + "&";
    let data = await api.get("projects" + qs + "page=" + page);
    refreshState
      ? dispatch(setProjects({ ...data.data, queryParams }))
      : dispatch(addProjects({ ...data.data, queryParams }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getMyProjects = (
  queryParams,
  page,
  refreshState = false
) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    let qs = !queryParams ? "?" : queryParams + "&";
    let data = await api.get("my_projects" + qs + "page=" + page);
    refreshState
      ? dispatch(setMyProjects({ ...data.data, queryParams }))
      : dispatch(addMyProjects({ ...data.data, queryParams }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getProjectDetailview = project_id => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    let [project, comments] = await Promise.all([
      api.get(`projects/${project_id}`),
      api.get(`projects/${project_id}/comment`),
    ]);

    dispatch(
      addProjectDetailview({
        ...project.data.data,
        comments: { data: comments.data.data, pagination: comments.data.pagination },
      })
    );
  } catch (err) {
    console.log({ err });
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getMoreProjectComments = (route, page) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const data = await api.get(route + "?page=" + page);
    dispatch(fetchMoreProjectComments({ ...data.data }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const commentInPostAction = (route, data) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const response = await api.post(route, data);
    return dispatch(addProjectComment({ ...response.data }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const projectBoostCategories = projects => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const promises = [api.get("projects/boost/categories")];

    if (projects === undefined || projects === null)
      promises.push(api.get("my_projects"));

    let [categories, myProjects] = await Promise.all(promises);

    if (projects === undefined || projects === null)
      dispatch(setUser({ projects: myProjects.data.data }));

    return categories.data.data;
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const boostProjectAction = data => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    await api.post(`projects/${data.project}/boost`, data);
    return true;
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const updateProjectAction = data => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    let imagePromises = [data.main_image];

    (data.images || []).map(el => (!el ? null : imagePromises.push(el.image)));

    let [main_image, ...images] = await Promise.all(
      imagePromises.map(image => {
        if (typeof image === "object") {
          let formData = new FormData();
          formData.append("file", image);

          return api
            .post("/uploads", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(data => data.data.data.url);
        } else {
          return image;
        }
      })
    );

    let resp = { ...data, media_id: main_image };

    data.main_image = main_image.split("amazonaws.com/").pop();
    data.images = images.map(image => image.split("amazonaws.com/").pop());

    data.interests = data.interests.map(interest => interest.id);
    data.categories = data.categories.map(category => category.id);

    await api.put(`projects/${data.id}`, { ...data });

    resp.images = resp.images.map(image => ({ image }));
    dispatch(updateProject({ ...resp }));

    return resp.id;
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const createProjectAction = data => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    let imagePromises = [data.main_image];
    (data.images || []).map(el => (!el ? null : imagePromises.push(el.image)));

    let [main_image, ...images] = await Promise.all(
      imagePromises.map(image => {
        if (typeof image === "object") {
          let formData = new FormData();
          formData.append("file", image);

          return api
            .post("/uploads", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(data => data.data.data.url);
        } else {
          return image;
        }
      })
    );

    data.main_image = main_image.split("amazonaws.com/").pop();
    data.images = images.map(image => image.split("amazonaws.com/").pop());

    data.interests = data.interests.map(interest => interest.id);
    data.categories = data.categories.map(category => category.id);

    let response = await api.post(`projects/add`, { ...data });

    console.log({ ...response });

    dispatch(projectCreated({ ...response.data.data }));

    return response.data.data.id;
  } catch (err) {
    console.log({ err });

    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const deleteProjectAction = id => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    await api.delete(`projects/${id}`);
    dispatch(deleteProject(id));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};
