import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getProjectDetailview, deleteProjectAction } from "./actions";
import ProjectDetailview from "../../components/Projects/ProjectDetailview";
import { getMoreProjectComments, commentInPostAction } from "./actions";
import ProjectForms from "./ProjectForms";
import ActionModalComponent from "../../components/UI/Modal/ActionModal";

const ProjectDetailviewContainer = ({ update }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const project = useSelector(state => state.projectState.project);
  const comments = project.comments;
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!project.id || project.id !== +match.params.projectId)
      dispatch(getProjectDetailview(match.params.projectId));
  }, [dispatch, match.params.projectId, project.id]);

  const fetchMoreComments = () => {
    if (
      comments.pagination &&
      comments.pagination.current_page < comments.pagination.last_page
    ) {
      dispatch(
        getMoreProjectComments(
          `projects/${project.id}/comment`,
          comments.pagination.current_page + 1
        )
      );
    }
  };

  const deleteProject = e => {
    e.preventDefault();
    setModal(true);
  };

  const deleteProjectHandler = () => {
    dispatch(deleteProjectAction(project.id)).then(() =>
      history.push("/projects/my")
    );
  };

  /**
   *
   * @param {String} text
   */
  const commentHandler = text => {
    return dispatch(commentInPostAction(`projects/${project.id}/comment`, { text }));
  };

  if (!project.id || project.id !== +match.params.projectId) return null;

  if (update) return <ProjectForms />;

  return (
    <React.Fragment>
      <ProjectDetailview
        fetchMoreComments={fetchMoreComments}
        commentHandler={commentHandler}
        deleteProjectHandler={deleteProject}
      />
      <ActionModalComponent
        show={modal}
        message={t("confirm_project_destroy")}
        actionButton={t("yes")}
        cancelButton={t("cancel")}
        onCloseHandler={() => setModal(false)}
        onSubmitHandler={deleteProjectHandler}
      />
    </React.Fragment>
  );
};

ProjectDetailviewContainer.propTypes = {
  update: PropTypes.bool,
};

export default ProjectDetailviewContainer;
