import React, { useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { BLACK_PLUS_SIGN, REMOVE } from "../../../assets";
import ProjectFormBanner from "./Banner";
import Image from "../../UI/Image";
import { useTranslation } from "react-i18next";

const Step6 = ({ changePageHandler, onChangeHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);

  const images = new Array(4);
  images.fill(1);

  const uploadHandler = (e, position) => {
    const { name, value, files } = e.target;
    onChangeHandler({ target: { name, value, type: "multiple", files, position } });
  };

  const removeHandler = (e, position) => {
    e.preventDefault();
    onChangeHandler({ target: { position, type: "removeImage" } });
  };

  return (
    <form onSubmit={e => changePageHandler(form, e, "step-7")} ref={form}>
      <ProjectFormBanner
        submitHandler={(e, location) => changePageHandler(form, e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-content">
            <h1>{t("add_images_to_project_gallery")}</h1>
            <p>{t("step_6_content")}</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-gallery">
            <ul>
              {images.map((val, index) => (
                <li key={index} className="image">
                  {!get(state, `images[${index}].image`, false) ? (
                    <Fragment>
                      <input
                        onChange={e => uploadHandler(e, index)}
                        type="file"
                        className="file-upload-input"
                        accept="image/*"
                      />
                      <div className="icon">
                        <BLACK_PLUS_SIGN />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Image src={state.images[index].image} />
                      <div className="image-delete">
                        <a onClick={e => removeHandler(e, index)} href="">
                          <REMOVE />
                        </a>
                      </div>
                    </Fragment>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-input ge-send">
            <input type="submit" value={t("continue")} className="button" />
          </div>
        </div>
      </div>
    </form>
  );
};

Step6.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Step6;
