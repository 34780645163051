/* eslint-disable no-useless-catch */
import api from "../../api";
import { toggleLoading } from "../constants";

export const requestResetPasswordLink = data => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const response = await api.post("accounts/forgot_password", data);
    if (response) return true;
  } catch (error) {
    throw error;
  } finally {
    dispatch(toggleLoading(false));
  }
};
