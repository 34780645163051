import React from "react";
import { Switch, Route } from "react-router-dom";

import DiscoverContainer from "./DiscoverContainer";
import MyProjectsContainer from "./MyProjectsContainer";
import ProjectDetailviewContainer from "./ProjectDetailview";
import ProjectBoost from "./ProjectBoost";
import ProjectForms from "./ProjectForms";

const ProjectContainer = () => {
  return (
    <Switch>
      <Route path="/projects/new" component={ProjectForms} />
      <Route exact path="/projects/projects-boost" component={ProjectBoost} />
      <Route exact path="/projects" component={DiscoverContainer} />
      <Route
        exact
        path="/projects/:projectId(\d+)"
        component={ProjectDetailviewContainer}
      />
      <Route path="/projects/:projectId(\d+)/update">
        <ProjectDetailviewContainer update />
      </Route>
      <Route exact path="/projects/my" component={MyProjectsContainer} />
      <Route
        exact
        path="/projects/my/:projectId(\d+)"
        component={ProjectDetailviewContainer}
      />
      <Route exact path="/projects/about" component={() => <div>About</div>} />
    </Switch>
  );
};

export default ProjectContainer;
