import React, { useState } from "react";
import PropTypes from "prop-types";
import DebouncedInput from "../UI/DebouncedInput";
import { useTranslation } from "react-i18next";

const ProfileSettings = ({
  username,
  password,
  newPassword,
  cPassword,
  isUsernameAvalible,
  checkUsernameHandler,
  formChangeHandler,
  updateUsernameHandler,
  updatePasswordHandler,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  return (
    <section className="settings-account">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <form
              onChange={formChangeHandler}
              onSubmit={updateUsernameHandler}
              autoComplete="new-password"
              action=""
            >
              <div className="settings-account-item">
                <div
                  className="settings-account-header collapsed"
                  data-toggle="collapse"
                  data-target="#acc-1"
                  aria-expanded="false"
                >
                  <h1>{t("change_username")}</h1>
                </div>
                <div id="acc-1" className="collapse">
                  <div className="settings-account-body">
                    <DebouncedInput
                      changeHandler={checkUsernameHandler}
                      isCorrect={isUsernameAvalible}
                      value={username}
                      name={"username"}
                      placeholder={t("username")}
                      required={true}
                      divClassName={"settings-account-input ge-input "}
                      inputClassName={"form-control"}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="save-button">
                    <button
                      type="submit"
                      href="javascript:void(0);"
                      className="button"
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <form
              onSubmit={updatePasswordHandler}
              autoComplete="new-password"
              action=""
            >
              <div className="settings-account-item">
                <div
                  className="settings-account-header collapsed"
                  data-toggle="collapse"
                  data-target="#acc-3"
                  aria-expanded="false"
                >
                  <h1>{t("change_password")}</h1>
                </div>
                <div id="acc-3" className="collapse">
                  <div className="settings-account-body">
                    <div className="settings-account-input ge-input ge-input-password">
                      <input
                        id="current-password-field"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control"
                        value={password}
                        onChange={formChangeHandler}
                        name="current_password"
                        placeholder={t("current_password")}
                        required
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className={
                          "toggle-password " +
                          (showPassword ? "pass-eye" : "pass-eye-off")
                        }
                      ></span>
                    </div>
                    <div className="settings-account-input ge-input ge-input-password">
                      <input
                        id="new-password-field"
                        type={showNewPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control"
                        value={newPassword}
                        name="new_password"
                        onChange={formChangeHandler}
                        required
                        placeholder={t("new_password")}
                      />
                      <span
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className={
                          "toggle-password " +
                          (showNewPassword ? "pass-eye" : "pass-eye-off")
                        }
                      ></span>
                    </div>
                    <div className="settings-account-input ge-input ge-input-password">
                      <input
                        id="confirm-password-field"
                        type={showCPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="form-control"
                        value={cPassword}
                        name="cPassword"
                        onChange={formChangeHandler}
                        required
                        onBlur={() => setShowErrors(true)}
                        placeholder={t("confirm_new_password")}
                      />
                      <span
                        onClick={() => setShowCPassword(!showCPassword)}
                        className={
                          "toggle-password " +
                          (showCPassword ? "pass-eye" : "pass-eye-off")
                        }
                      ></span>
                    </div>
                    {!showErrors ? null : (
                      <p>
                        {newPassword !== cPassword
                          ? t("c_password_error_required")
                          : ""}
                      </p>
                    )}
                  </div>
                  <div className="save-button">
                    <button
                      type="submit"
                      href="javascript:void(0);"
                      className="button"
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

ProfileSettings.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
  cPassword: PropTypes.string.isRequired,
  isUsernameAvalible: PropTypes.bool,
  checkUsernameHandler: PropTypes.func.isRequired,
  updateUsernameHandler: PropTypes.func.isRequired,
  updatePasswordHandler: PropTypes.func.isRequired,
  formChangeHandler: PropTypes.func.isRequired,
};

export default ProfileSettings;
