import api from "../../api";

import { toggleLoading } from "../constants";
import {
  addProjectComment,
  editProjectComment,
  deleteProjectComment,
  fetchMoreProjectComments,
  thxProjectComment,
  addReplyToProjectComment,
  editReplyOfProjectComment,
  deleteReplyOfProjectComment,
} from "../Projects/constants";

export const commentInProjectAction = ({ projectId, text }) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const response = await api.post(`projects/${projectId}/comment`, { text });
    return dispatch(addProjectComment({ ...response.data }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const editProjectCommentAction = data => async dispatch => {
  let { projectId, commentId, text } = data;
  dispatch(toggleLoading(true));
  try {
    await api.put(`projects/${projectId}/comment/${commentId}`, {
      text,
    });
    return dispatch(editProjectComment({ commentId, text }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const deleteProjectCommentAction = data => async dispatch => {
  let { projectId, commentId } = data;
  dispatch(toggleLoading(true));
  try {
    await api.delete(`projects/${projectId}/comment/${commentId}`);
    return dispatch(deleteProjectComment({ commentId }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const replyToProjectCommentAction = data => async dispatch => {
  let { commentId, text } = data;
  dispatch(toggleLoading(true));
  try {
    const response = await api.post(`project_comments/${commentId}/replies`, {
      text,
    });
    return dispatch(addReplyToProjectComment({ ...response.data, commentId }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const editReplyOfProjectCommentAction = data => async dispatch => {
  let { commentId, text, replyId } = data;
  dispatch(toggleLoading(true));
  try {
    await api.put(`project_comments/${commentId}/replies/${replyId}`, { text });
    return dispatch(editReplyOfProjectComment({ text, commentId, replyId }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const deleteReplyOfProjectCommentAction = data => async dispatch => {
  let { commentId, replyId } = data;
  dispatch(toggleLoading(true));
  try {
    await api.delete(`project_comments/${commentId}/replies/${replyId}`);
    return dispatch(deleteReplyOfProjectComment({ commentId, replyId }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const thxProjectCommentAction = ({ commentId, thank }) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const response = await api.post(`/project_comments/${commentId}/thx`, { thank });
    return dispatch(thxProjectComment({ ...response.data.data }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const reportProjectCommentAction = ({ commentId }) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    await api.post(`/project_comments/${commentId}/report`);
    return true;
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};

export const getMoreProjectComments = ({ page, projectId }) => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const data = await api.get(`projects/${projectId}/comment?page=${page}`);
    dispatch(fetchMoreProjectComments({ ...data.data }));
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(toggleLoading(false));
  }
};
