import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { getProjectCategories } from "./actions";
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import icons from "../../helpers/icons";

const CategoriesContainer = ({ multiple, preSelected }) => {
  const { categories } = useSelector(s => s.projectState);
  const dispatch = useDispatch();

  const [queryString, setQueryString] = useQueryParam(
    "category",
    withDefault(NumberParam, 1)
  );

  useEffect(() => {
    if (categories.length === 0) dispatch(getProjectCategories());
  }, [categories, dispatch]);

  /**
   *
   * @param {Event} e
   * @param {Number} category
   */
  const setActive = (e, category) => {
    if (multiple) {
      let ids = [...preSelected];
      let index = -1;
      for (let el in ids) {
        if (ids[el].id === category.id) {
          index = el;
          break;
        }
      }
      index !== -1 ? ids.splice(index, 1) : ids.push(category);
      multiple(ids);
    } else {
      e.preventDefault();
      setQueryString(category.id);
    }
  };

  /**
   *
   * @param {Event} e
   * @param {Number} id
   */
  const setActiveClassName = id => {
    if (multiple) {
      for (let el of preSelected) if (el.id === id) return true;
      return false;
    } else {
      return id === +queryString;
    }
  };

  if (multiple)
    return (
      <ul>
        {categories.map((category, index) => {
          return (
            <li key={index}>
              <label>
                <input
                  onClick={e => setActive(e, category)}
                  checked={setActiveClassName(category.id)}
                  type="checkbox"
                  name=""
                />
                <div className="icon">
                  <i className={icons[+category.id - 1]}></i>
                  {category.name}
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    );

  return (
    <ul>
      {categories.map((category, index) => {
        return (
          <li
            className={setActiveClassName(category.id) ? "active" : ""}
            key={index}
          >
            <a onClick={e => setActive(e, category)} href="javascript:void(0);">
              <i className={icons[+category.id - 1]}></i>
              {category.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

CategoriesContainer.propTypes = {
  multiple: PropTypes.func,
  preSelected: PropTypes.array,
};

export default CategoriesContainer;
