import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Switch, Route, useRouteMatch } from "react-router-dom";

import RegisterForm from "../../components/Auth/RegisterForm";
import ConfirmRegister from "../../components/Auth/ConfirmRegister";

import { register, checkUsername } from "./actions";

const RegisterContainer = () => {
  const [state, setState] = useState({ email: "", password: "", username: "" });
  const [usernameAvailability, setUsernameAvailability] = useState(undefined);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  /**
   *
   * @param {Event} e
   */
  const formHandler = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setState(state => ({ ...state, [name]: value }));
  };

  /**
   *Login/Form
   * @param {Event} e
   */
  const submitHandler = async e => {
    e.preventDefault();
    dispatch(register({ ...state }))
      .then(() => history.push("/"))
      .catch(() => history.goBack());
  };

  /**
   *
   * @param {Event} e
   */
  const checkUsernameHandler = async value =>
    dispatch(checkUsername(value)).then(val => {
      setUsernameAvailability(val);
    });

  return (
    <Switch>
      <Route exact path={match.url + "/confirm"}>
        <ConfirmRegister
          username={state.username}
          email={state.email}
          submitHandler={submitHandler}
        />
      </Route>
      <Route path={match.url}>
        <RegisterForm
          state={state}
          changeHandler={formHandler}
          checkUsernameHandler={checkUsernameHandler}
          usernameAvailability={usernameAvailability}
        />
      </Route>
    </Switch>
  );
};

RegisterContainer.propTypes = {};

export default RegisterContainer;
