/* eslint-disable default-case */
import { TOGGLE_LOADING, TOGGLE_MODAL } from "./constants";

const globalState = {
  loading: false,
  modal: {},
};

const reducer = (state = globalState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      state = { ...state, loading: action.value };
      break;
    case TOGGLE_MODAL:
      state = { ...state, modal: { ...state.modal, ...action.value } };
      break;
  }
  return state;
};

export default reducer;
