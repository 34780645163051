import React from "react";
import { Switch, Route } from "react-router-dom";

import { PrivateRoute, NoAuthRoute } from "./router";

import AppContainer from "./containers/App";
import MembershipContainer from "./containers/Membership";
import NotFoundPage from "./containers/NotFoundPage";
import AuthContainer from "./containers/Auth";

const App = () => {
  return (
    <Switch>
      <Route exact path="/select-membership" component={MembershipContainer} />
      <NoAuthRoute path="/auth" component={AuthContainer} />
      <PrivateRoute path="/" component={AppContainer} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default App;
