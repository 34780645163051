import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

const Loading = ({ locally, loading }) => {
  let isLoading = useSelector(s => s.globalState.loading);

  if (locally) isLoading = loading;

  return (
    <section className={isLoading ? "holder" : "hide"}>
      <div className={"override"}>
        <ClipLoader
          css={"override"}
          sizeUnit={"px"}
          size={80}
          color={"#88CD46"}
          loading={isLoading}
        />
      </div>
    </section>
  );
};

Loading.propTypes = {
  locally: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Loading;
