import React, { useRef } from "react";
import PropTypes from "prop-types";
import ProjectFormBanner from "./Banner";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Step1 = ({ changePageHandler, onChangeHandler }) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const state = useSelector(state => state.projectState.mutateProject);

  return (
    <form onChange={onChangeHandler} ref={form}>
      <ProjectFormBanner
        submitHandler={(e, location) => changePageHandler(form, e, location)}
      />
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="projects-new-content">
            <h1>{t("add_project_title")}</h1>
            <p>{t("step_1_content")}</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="projects-new-input ge-input">
            <input
              type="text"
              required
              defaultValue={state.title}
              name={"title"}
              minLength={5}
              maxLength={500}
              className="form-control"
              placeholder={t("add_project_title")}
            />
          </div>
          <div className="projects-new-input ge-input">
            <textarea
              className="form-control"
              minLength={20}
              maxLength={500}
              placeholder={t("short_description")}
              defaultValue={state.description}
              name={"description"}
              required
            ></textarea>
            <span className="info">10-50 words</span>
          </div>
          <div className="projects-new-input ge-send">
            <input
              onClick={e => changePageHandler(form, e, "step-2")}
              type="button"
              value={t("continue")}
              className="button"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

Step1.propTypes = {
  changePageHandler: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default Step1;
