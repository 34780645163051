import React from "react";
import PropTypes from "prop-types";

import Comment from "./Comment";

const Reply = ({
  reply,
  editReplyHandler,
  deleteReplyHandler,
  reportReplyHandler,
}) => {
  return (
    <li>
      <div className="comments-item">
        <div className="comments-img-r">
          <img src={reply.user.media_url} alt="" />
        </div>
        <Comment
          deleteCommentHandler={deleteReplyHandler}
          editCommentHandler={editReplyHandler}
          reportCommentHandler={reportReplyHandler}
          isReply
          comment={reply}
        />
      </div>
    </li>
  );
};

Reply.propTypes = {
  reply: PropTypes.object.isRequired,
  editReplyHandler: PropTypes.func.isRequired,
  deleteReplyHandler: PropTypes.func.isRequired,
  reportReplyHandler: PropTypes.func.isRequired,
};

export default Reply;
