import React, { useState } from "react";
import ProfileBanner from "../../components/Profile/ProfileBanner";
import ProfileSettings from "../../components/Profile/Settings";
import { useSelector, useDispatch } from "react-redux";

import { checkUsername, editUsernameAction, changePassword } from "./actions";

const SettingsContainer = () => {
  const user = useSelector(s => s.user);
  const [state, setState] = useState({ username: user.username });
  const [isUsernameAvalible, setUsernameAvailability] = useState(undefined);
  const dispatch = useDispatch();

  const checkUsernameHandler = value => {
    if (encodeURIComponent(value) !== value) {
      setUsernameAvailability(false);
      setState({ ...state, canUpdateProfile: false });
      return;
    }
    dispatch(checkUsername(encodeURIComponent(value))).then(val => {
      val = value === user.username || val;
      setUsernameAvailability(val);
    });
  };
  /**
   *
   * @param {Event} e
   */
  const formChangeHandler = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  /**
   *
   * @param {Event} e
   */
  const updateUsernameHandler = e => {
    e.preventDefault();
    let { username } = state;
    username = username.trim();
    if (user.username === username || !(isUsernameAvalible && username)) return;
    dispatch(editUsernameAction({ username }))
      .then(() => alert("Success"))
      .catch(() => alert("Error"));
  };

  /**
   *
   * @param {Event} e
   */
  const updatePasswordHandler = e => {
    e.preventDefault();
    const { current_password, new_password, cPassword } = state;

    if (!(current_password && new_password && new_password === cPassword)) return;

    dispatch(changePassword({ current_password, new_password }))
      .then(() => {
        alert("success");
        setState({ username: state.username });
      })
      .catch(err => alert(err));
  };

  return (
    <React.Fragment>
      <ProfileBanner />
      <ProfileSettings
        username={state.username}
        password={state.current_password || ""}
        newPassword={state.new_password || ""}
        cPassword={state.cPassword || ""}
        isUsernameAvalible={isUsernameAvalible}
        checkUsernameHandler={checkUsernameHandler}
        formChangeHandler={formChangeHandler}
        updateUsernameHandler={updateUsernameHandler}
        updatePasswordHandler={updatePasswordHandler}
      />
    </React.Fragment>
  );
};

export default SettingsContainer;
