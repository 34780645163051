import React from "react";
import PropTypes from "prop-types";

import icons from "../../helpers/icons";
import { useTranslation } from "react-i18next";

const BoostProjectForm = ({
  projects,
  categories,
  onChangeHandler,
  state,
  onSubmitHandler,
}) => {
  const { t } = useTranslation();

  return (
    <section className="projects-boost">
      <div className="container">
        <form onChange={onChangeHandler} onSubmit={onSubmitHandler}>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div className="projects-boost-input ge-select">
                <select
                  name="project"
                  defaultValue={state.project || -1}
                  className="orderby"
                  required
                >
                  <option value={-1} hidden disabled>
                    {t("choose_your_project")}
                  </option>
                  {projects.map(project => (
                    <option key={project.id} value={project.id}>
                      {project.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="projects-boost-multiselect">
              <h2>{t("im_interested_in")}</h2>
            </div>
            <div className="col-md-10">
              <div className="ge-multiselect">
                <ul>
                  {categories.map(category => {
                    return (
                      <li key={category.id}>
                        <label>
                          <input type="checkbox" name={"category-" + category.id} />
                          <div className="icon-box">
                            <i className={icons[+category.id - 1]}></i>
                          </div>
                          <div className="icon-text">
                            <span>{category.name}</span>
                          </div>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6">
              <div className="projects-boost-input ge-input">
                <textarea
                  name="text"
                  className="form-control"
                  placeholder="Your message"
                  required
                  maxLength={500}
                  minLength={20}
                ></textarea>
                <span className="info">{t("no_characters", { num: "20-500" })}</span>
              </div>
              <div className="projects-boost-content">
                <p>{t("review_request")}</p>
                <p>{t("two_possibilities")} </p>
                <ul>
                  <li>{t("possibility_1")}</li>
                  <li>{t("possibility_2")}</li>
                </ul>
              </div>
              <div className="projects-boost-input ge-send">
                <input type="submit" value={t("request_now")} className="button" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

BoostProjectForm.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

export default BoostProjectForm;
