import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import RequestResetPassword from "../../components/Auth/RequestResetPassword";

import { requestResetPasswordLink } from "./actions";

const RequestResetPasswordContainer = () => {
  const [state, setState] = useState({ email: "" });
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   *
   * @param {Event} e
   */
  const onChangeHandler = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  /**
   *
   * @param {Event} e
   */
  const onSubmitHandler = async e => {
    e.preventDefault();

    dispatch(requestResetPasswordLink(state)).then(() =>
      history.push("/auth/login")
    );
  };

  return (
    <RequestResetPassword
      onSubmitHandler={onSubmitHandler}
      onChangeHandler={onChangeHandler}
    />
  );
};

export default RequestResetPasswordContainer;
