import React, { useState } from "react";
import PropTypes from "prop-types";

import { MORE, HEART_FILL, HEART_OUTLINE } from "../../assets";
import CommentProperties from "./CommentProperties";
import CommentInput from "./CommentInput";
import { API_MENTION_REGEX } from "../../helpers/regex";

const Comment = ({
  comment,
  isReply,
  editCommentHandler,
  deleteCommentHandler,
  reportCommentHandler,
  thxHandler,
  toggleReply,
}) => {
  const [edit, setEdit] = useState(false);
  let {
    user: { id: userId, username },
    text,
    created_at,
    id,
  } = comment;

  const formatMentions = text =>
    (text.match(API_MENTION_REGEX) || []).reduce(
      (comment, mention) =>
        (comment = comment.replace(mention, `<a href=" #">${mention}</a>`)),
      text
    );

  const formatEditComment = text =>
    (text.match(API_MENTION_REGEX) || []).reduce(
      (comment, mention) =>
        (comment = comment.replace(mention, `@[${mention.split("@").pop()}]`)),
      text
    );

  return (
    <div className={`comments-content ${!isReply ? "" : "comments-content-r"}`}>
      <div className="comments-dropdown">
        <a id="commentsDropDown" data-toggle="dropdown">
          <MORE />
        </a>
        <CommentProperties
          onClickReport={() => reportCommentHandler(id)}
          onClickEdit={() => setEdit(prevValue => !prevValue)}
          onClickDelete={() => deleteCommentHandler(id)}
          authorId={userId}
        />
      </div>
      <h3>@{username}</h3>
      <span>{created_at}</span>
      {!edit ? (
        <p dangerouslySetInnerHTML={{ __html: formatMentions(text) }}></p>
      ) : (
        <CommentInput
          value={formatEditComment(text)}
          submitHandler={text =>
            editCommentHandler(id, text).then(() => setEdit(false))
          }
        />
      )}
      {isReply ? null : (
        <div className="comments-react">
          <ul>
            <li>
              <a onClick={thxHandler} href="#">
                {comment.is_liked ? <HEART_FILL /> : <HEART_OUTLINE />}
              </a>
            </li>
            <li
              onClick={() => toggleReply(prevValue => !prevValue)}
              className="reply"
            >
              Reply
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  isReply: PropTypes.bool,
  reportCommentHandler: PropTypes.func,
  editCommentHandler: PropTypes.func,
  deleteCommentHandler: PropTypes.func,
  thxHandler: PropTypes.func,
  toggleReply: PropTypes.func,
};

export default Comment;
