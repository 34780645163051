import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
} from "../../components/Projects/forms";

import { prepareProjectUpdate, mutateProject } from "./constants";
import { updateProjectAction, createProjectAction } from "./actions";
import ModalComponent from "../../components/UI/Modal";
import { useTranslation } from "react-i18next";

const ProjectForms = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const state = useSelector(state => state.projectState.mutateProject);
  const [modal, setModal] = useState({
    show: false,
    message: "",
    isError: false,
    button: "ok",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.id !== match.params.projectId) dispatch(prepareProjectUpdate());
  }, [dispatch, match.params.projectId, state.id]);

  /**
   *
   * @param {React.SyntheticEvent} e
   */
  const onChangeHandler = e => {
    let { name, value, type, files, position } = e.target;

    let interests = [...(state.interests || [])];
    let images = [...(state.images || [])];

    switch (type) {
      case "file":
        dispatch(mutateProject({ media_id: files[0] }));
        break;
      case "multiple":
        images[position] = { image: files[0] };
        dispatch(mutateProject({ images }));
        break;

      case "removeImage":
        images[position] = null;
        dispatch(mutateProject({ images }));
        break;
      case "interests":
        let index = -1;
        for (let el in interests) {
          if (interests[el].id === value.id) {
            index = el;
            break;
          }
        }
        index === -1 ? interests.push(value) : interests.splice(index, 1);
        dispatch(mutateProject({ interests }));
        break;
      default:
        dispatch(mutateProject({ [name]: value }));
        break;
    }
  };

  /**
   *
   * @param {React.Ref} ref
   * @param {React.SyntheticEvent} e
   * @param {String} location
   */
  const changePageHandler = (ref, e, location) => {
    e.preventDefault();
    let valid = ref.current.reportValidity();
    if (valid) {
      history.push(location);
    }
  };

  /**
   *
   * @param {React.SyntheticEvent} e
   */
  const onSubmitHandler = e => {
    e.preventDefault();

    const data = { ...state };
    data.main_image = data.media_id;

    if (!data.title || !data.description) return history.push("step-1");
    if (!data.categories || data.categories.length < 1)
      return history.push("step-2");
    if (!data.main_image) return history.push("step-4");
    if (!data.interests || data.interests.length < 1) return history.push("step-7");

    let request = !match.params.projectId
      ? createProjectAction
      : updateProjectAction;

    dispatch(request(data)).then(id =>
      setModal(prevState => {
        return {
          ...prevState,
          show: true,
          message: !match.params.projectId
            ? t("project_created")
            : t("project_updated"),
          isError: false,
          button: "ok",
          id,
        };
      })
    );
  };

  return (
    <section className="projects-new">
      <div className="container">
        <Switch>
          <Route path={`${match.url}/step-1`}>
            <Step1
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
            />
          </Route>
          <Route path={`${match.url}/step-2`}>
            <Step2
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
            />
          </Route>
          <Route path={`${match.url}/step-3`}>
            <Step3
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
            />
          </Route>
          <Route path={`${match.url}/step-4`}>
            <Step4
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
            />
          </Route>
          <Route path={`${match.url}/step-5`}>
            <Step5
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
            />
          </Route>
          <Route path={`${match.url}/step-6`}>
            <Step6
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
            />
          </Route>
          <Route path={`${match.url}/step-7`}>
            <Step7
              onChangeHandler={onChangeHandler}
              changePageHandler={changePageHandler}
              onSubmitHandler={onSubmitHandler}
            />
          </Route>
          <Route>
            <Redirect to={`${match.url}/step-1`} />
          </Route>
        </Switch>
      </div>
      <ModalComponent
        {...modal}
        onCloseHandler={() => {
          setModal({ show: false });
          history.push(`/projects/${modal.id}`);
        }}
      />
    </section>
  );
};

ProjectForms.propTypes = {};

export default ProjectForms;
