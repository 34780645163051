import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConfirmRegister = ({ username, email, submitHandler }) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (!username || !email) {
    history.push("/auth/register");
    return null;
  }

  return (
    <div className="row justify-content-end">
      <div className="col-md-7 col-lg-7 col-xl-6">
        <div className="login-wrapper">
          <div className="login-top">
            <p>{t("already_have_account")}</p>
            <a href="javascript:void(0);" className="button">
              {t("sign_in")}
            </a>
          </div>
          <div className="login-title">
            <h1>{t("confirm_account")}</h1>
            <p>{t("enter_details")}</p>
          </div>
          <div className="login-form">
            <form onSubmit={submitHandler} action="">
              <div className="login-input ge-input">
                <p>
                  Your username is <a href="javascript:void(0);">@{username}</a>,
                  with e-mail <a href="javascript:void(0);">{email}</a>
                </p>
              </div>
              <div className="login-submit">
                <input
                  type="submit"
                  className="button"
                  value={t("confirm_continue")}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmRegister.propTypes = {
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default ConfirmRegister;
