/* eslint-disable default-case */
import { PROFILE, ADD_ERRORS, RESET_ERROR_STATE } from "./constants";

const authState = {};

const reducer = (state = authState, action) => {
  switch (action.type) {
    case PROFILE:
      return {
        ...state,
        ...action.value,
      };
    case ADD_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.value,
        },
      };
    case RESET_ERROR_STATE:
      return {
        ...state,
        errors: {},
      };
  }
  return state;
};

export default reducer;
