/* eslint-disable default-case */
import get from "lodash/get";

import {
  ADD_MY_PROJECTS,
  ADD_PROJECTS,
  PROJECT_DETAILVIEW,
  SET_CATEGORIES,
  SET_MY_PROJECTS,
  SET_PROJECTS,
  FETCH_MORE_PROJECT_COMMENTS,
  ADD_PROJECT_COMMENT,
  PREPARE_PROJECT_UPDATE,
  PREPARE_PROJECT_CREATE,
  MUTATE_PROJECT,
  SET_INTERESTS,
  UPDATE_PROJECT,
  PROJECT_CREATED,
  THX_PROJECT_COMMENT,
  ADD_REPLY_TO_PROJECT_COMMENT,
  EDIT_PROJECT_COMMENT,
  EDIT_REPLY_OF_PROJECT_COMMENT,
  DELETE_PROJECT_COMMENT,
  DELETE_REPLY_OF_PROJECT_COMMENT,
  DELETE_PROJECT,
} from "./constants";

const projectState = {
  myProjects: {},
  projects: {},
  project: {},
  mutateProject: {},
  categories: [],
};

const reducer = (state = projectState, action) => {
  switch (action.type) {
    case ADD_PROJECTS:
      state = {
        ...state,
        projects: {
          data: [...get(state, "projects.data", []), ...action.value.data],
          pagination: { ...action.value.pagination },
          queryParams: action.value.queryParams,
        },
      };
      break;
    case SET_PROJECTS:
      state = {
        ...state,
        projects: {
          data: [...action.value.data],
          pagination: { ...action.value.pagination },
          queryParams: action.value.queryParams,
        },
      };
      break;
    case ADD_MY_PROJECTS:
      state = {
        ...state,
        myProjects: {
          data: [...get(state, "myProjects.data", []), ...action.value.data],
          pagination: { ...action.value.pagination },
          queryParams: action.value.queryParams,
        },
      };
      break;
    case SET_MY_PROJECTS:
      state = {
        ...state,
        myProjects: {
          data: [...action.value.data],
          pagination: { ...action.value.pagination },
          queryParams: action.value.queryParams,
        },
      };
      break;
    case PROJECT_DETAILVIEW:
      state = {
        ...state,
        project: { ...action.value },
      };
      break;
    case FETCH_MORE_PROJECT_COMMENTS:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            data: [...(state.project.comments.data || []), ...action.value.data],
            pagination: action.value.pagination,
          },
        },
      };
      break;
    case ADD_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...state.project.comments,
            data: [action.value.data, ...(state.project.comments.data || [])],
            pagination: {
              ...(state.project.comments.pagination || {}),
              total: state.project.comments.pagination.total + 1,
              extra: (state.project.comments.pagination.extra || 0) + 1,
            },
          },
        },
      };
      break;
    case EDIT_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...state.project.comments,
            data: get(state, "project.comments.data", []).map(comment => {
              if (comment.id === action.value.commentId) {
                return { ...comment, text: action.value.text };
              }
              return comment;
            }),
          },
        },
      };
      break;
    case DELETE_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...state.project.comments,
            data: get(state, "project.comments.data", []).filter(
              comment => comment.id !== action.value.commentId
            ),
          },
        },
      };
      break;
    case ADD_REPLY_TO_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...get(state, "project.comments", {}),
            data: get(state, "project.comments.data", []).map(comment => {
              if (comment.id === action.value.commentId) {
                return {
                  ...comment,
                  reply_count: comment.reply_count + 1,
                  replies: [...get(comment, "replies", []), action.value.data],
                };
              }
              return comment;
            }),
          },
        },
      };
      break;
    case EDIT_REPLY_OF_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...state.project.comments,
            data: get(state, "project.comments.data", []).map(comment => {
              if (comment.id === action.value.commentId) {
                return {
                  ...comment,
                  replies: get(comment, "replies", []).map(reply => {
                    if (reply.id === action.value.replyId) {
                      return { ...reply, text: action.value.text };
                    }
                    return reply;
                  }),
                };
              }
              return comment;
            }),
          },
        },
      };
      break;
    case DELETE_REPLY_OF_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...state.project.comments,
            data: get(state, "project.comments.data", []).map(comment => {
              if (comment.id === action.value.commentId) {
                return {
                  ...comment,
                  replies: get(comment, "replies", []).filter(
                    reply => reply.id !== action.value.replyId
                  ),
                };
              }
              return comment;
            }),
          },
        },
      };
      break;
    case THX_PROJECT_COMMENT:
      state = {
        ...state,
        project: {
          ...state.project,
          comments: {
            ...state.project.comments,
            data: state.project.comments.data.map(data => {
              if (data.id !== action.value.id) return data;
              return { ...data, ...action.value };
            }),
          },
        },
      };
      break;
    case SET_CATEGORIES:
      state = {
        ...state,
        categories: [...action.value],
      };
      break;
    case SET_INTERESTS:
      state = {
        ...state,
        interests: [...action.value],
      };
      break;

    case PREPARE_PROJECT_UPDATE:
      state = {
        ...state,
        mutateProject: { ...state.project },
      };
      break;

    case PREPARE_PROJECT_CREATE:
      state = {
        ...state,
        mutateProject: {},
      };
      break;

    case MUTATE_PROJECT:
      state = {
        ...state,
        mutateProject: { ...state.mutateProject, ...action.value },
      };
      break;
    case UPDATE_PROJECT:
      state = {
        ...state,
        project: { ...state.project, ...state.mutateProject },
        projects: {
          ...state.projects,
          data: (state.projects.data || []).map(project => {
            if (project.id === state.mutateProject.id) {
              return { ...state.mutateProject };
            }
            return project;
          }),
        },
        myProjects: {
          ...state.myProjects,
          data: (state.myProjects.data || []).map(project => {
            if (project.id === state.mutateProject.id) {
              return { ...state.mutateProject };
            }
            return project;
          }),
        },
      };
      break;
    case PROJECT_CREATED:
      state = {
        ...state,
        projects: {
          ...state.projects,
          data: [{ ...action.value }, ...get(state, "projects.data", [])],
        },
        myProjects: {
          ...state.myProjects,
          data: [{ ...action.value }, ...get(state, "myProjects.data", [])],
        },
      };
      break;
    case DELETE_PROJECT:
      state = {
        ...state,
        projects: {
          ...state.projects,
          data: get(state, "projects.data", []).filter(
            data => data.id !== action.value
          ),
        },
        myProjects: {
          ...state.myProjects,
          data: get(state, "myProjects.data", []).filter(
            data => data.id !== action.value
          ),
        },
      };
      break;
  }
  return state;
};

export default reducer;
