import React from "react";
import ThirdPartyAuth from "../../components/Auth/ThirdPartyAuth";
import { useDispatch } from "react-redux";
import { fbAuth, gpAuth } from "./actions";
import { useHistory } from "react-router-dom";

const ThirdPartyAuthContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const fbAuthHandler = response => {
    dispatch(fbAuth({ access_token: response.accessToken })).then(() => {
      history.push("/");
    });
  };

  const gpAuthHandler = response => {
    dispatch(gpAuth({ access_token: response.accessToken })).then(() => {
      history.push("/");
    });
  };

  return (
    <ThirdPartyAuth fbAuthHandler={fbAuthHandler} gpAuthHandler={gpAuthHandler} />
  );
};

ThirdPartyAuthContainer.propTypes = {};

export default ThirdPartyAuthContainer;
