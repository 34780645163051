import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";
import ProjectsContainer from "../../containers/Projects/index";
import Loading from "../../components/UI/Loading";
import ProfileContainer from "../Profile";

import { loadProfile } from "./actions";
import GlobalModalComponent from "../../components/UI/Modal/GlobalModal";

const AppContainer = () => {
  const user = useSelector(s => s.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !user.id) dispatch(loadProfile());
  }, [dispatch, user]);

  if (!user || !user.id) return <Loading locally loading />;

  return (
    <React.Fragment>
      <Loading />
      <GlobalModalComponent />
      <Header />
      <Switch>
        <Route path="/projects" component={ProjectsContainer} />
        <Route path="/profile" component={ProfileContainer} />
        <Route exact path="/" component={() => <div>Dashboard</div>} />
      </Switch>
    </React.Fragment>
  );
};

export default AppContainer;
